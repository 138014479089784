import React from 'react';
import classnames from 'classnames';
import { Avatar, Divider, Dropdown, Spin, Card, Button } from 'antd';
import { useHistory } from 'react-router-dom';

import IntlMessages from 'util/IntlMessages';
import { MoreOutlined, UnorderedListOutlined, UserOutlined } from '@ant-design/icons';
import ComponentBlocker from 'components/ComponentBlocker';
import { ALLOWED_ROLES, useFirestoreQuery } from 'packages/utils';
import { db } from 'firebase/firebase';
import { doc, query } from 'firebase/firestore';

import { eventPropType } from 'packages/utils/proptypes/event';
import AvatarLocation from 'components/Location/AvatarLocation';
import TriggerProgress from '../TriggerProgress';
import { componentValue } from '../EventComponents';
import { getRouteToEvent } from '../../constants';
import EventDatetime from '../EventDatetime';

import styles from './index.module.less';
import { getFullUrl } from '../../../utils/functions';

const MAX_COMPONENTS = 3;

const TimelineEvent = ({ event }) => {
  const history = useHistory();

  const color = `rgba(${event.eventType.color.r}, ${event.eventType.color.g}, ${event.eventType.color.b}, ${event.eventType.color.a})`;
  const extraComponents = event.eventType.components.length - MAX_COMPONENTS;
  const hasTrigger = event?.trigger || null;
  const locationMarked = event?.locations?.[0];

  const { triggerId, execution, division, organization } = event;
  const { data: trigger, loading: triggerLoading } = useFirestoreQuery(
    !hasTrigger &&
      execution &&
      organization &&
      query(
        doc(
          db,
          'organizations',
          organization?.id,
          'divisions',
          division.id,
          'executions',
          execution.id,
          'triggers',
          triggerId,
        ),
      ),
    [execution?.organization.id, execution?.division.id, execution?.id, triggerId],
  );

  const triggerDetail = hasTrigger ? event.trigger : trigger;

  const handleEventDetail = () =>
    history.push(
      getFullUrl(getRouteToEvent(event.eventType.divId, event.id), history.location.pathname),
    );

  const menu = () => (
    <Card bodyStyle={{ padding: '5px' }}>
      <div className="gx-d-flex gx-flex-column">
        <ComponentBlocker
          divId={event.eventType.divId}
          allowedRoles={ALLOWED_ROLES.ORGANIZATIONS.EVENTS_SUMMARY.GET}
        >
          <Button key="0" type="text" className="gx-m-0" onClick={handleEventDetail}>
            <IntlMessages id="timeline.event.menu.viewDetail" />
          </Button>
        </ComponentBlocker>
        <ComponentBlocker
          divId={event.eventType.divId}
          allowedRoles={ALLOWED_ROLES.ORGANIZATIONS.EVENTS_SUMMARY.DELETE}
        >
          <Button key="2" type="text" className="gx-m-0">
            <IntlMessages id="timeline.event.menu.deleteEvent" />
          </Button>
        </ComponentBlocker>
      </div>
    </Card>
  );

  const componentsOrdered = event.eventType.components.sort((a, b) => a.order - b.order) || [];

  return (
    <div className={classnames('gx-timeline-item', styles.mainContainer)}>
      <div
        className={classnames('gx-timeline-badge', styles.badge)}
        style={{
          backgroundColor: color,
        }}
      >
        <i className="material-icons">{event.eventType.icon}</i>
      </div>
      <EventDatetime datetime={event.createdAtDevice} className="gx-mr-2" />
      <div className={styles.body}>
        <div
          className={styles.colorBar}
          style={{
            backgroundColor: color,
          }}
        />
        <div className={styles.content}>
          <div className="gx-flex-row">
            {event.division?.name && (
              <div className="gx-flex-row">
                <span className={classnames('material-icons', styles.divisionIcon)}>groups</span>
                <h3 className={styles.divisionName}>
                  <IntlMessages
                    id="timeline.event.division"
                    values={{ name: event.division.name }}
                  />
                </h3>
              </div>
            )}
            <div className={styles.options}>
              <Dropdown dropdownRender={menu} trigger={['click']}>
                <MoreOutlined className={styles.icon} />
              </Dropdown>
            </div>
          </div>
          <h1 className={classnames('gx-mb-2', styles.title)}>{event.eventType.name}</h1>
          {triggerLoading && <Spin />}
          <div className="gx-flex-row gx-align-items-center gx-guarnic-gap-2">
            {triggerDetail && <TriggerProgress trigger={triggerDetail} />}
            <div className="gx-flex-row gx-justify-content-center">
              {locationMarked && (
                <div className="gx-flex-row gx-align-items-center gx-guarnic-gap-2">
                  <div>
                    <AvatarLocation location={locationMarked} />
                  </div>
                  {locationMarked.name}
                </div>
              )}
            </div>
          </div>
          <Divider />
          {componentsOrdered
            .slice(0, MAX_COMPONENTS)
            .map((component, idx) => componentValue(component, idx))}
        </div>
        <div className={styles.footer}>
          <Avatar
            icon={<UserOutlined />}
            shape="circle"
            className={classnames('gx-size-30', styles.avatar)}
            src={event.user?.photoURL}
          />
          <span className="gx-mr-auto gx-ml-3">{`${event.user?.firstName} ${event.user?.lastName}`}</span>
          {extraComponents > 0 && (
            <div className={styles.extraComponents}>
              <UnorderedListOutlined className="gx-mr-1" />+{extraComponents}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

TimelineEvent.propTypes = {
  event: eventPropType.isRequired,
};

export default React.memo(TimelineEvent);
