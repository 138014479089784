/* eslint-disable no-param-reassign */
/* eslint-disable no-underscore-dangle */
import moment from 'moment';
import { get } from 'packages/utils/storage';
import { EXECUTION_STATUS } from 'constants/commons';
import { getTriggersByExecution } from 'util/firebase-operations/executions/triggers/get';
import { getUserTimeZoneDate, hasUsersAssigned } from '..';
import { getCompletitionData } from '../triggers';

export const getActiveAndNextExecutions = executions => {
  const activeExecutions = [];
  const nextExecutions = [];
  const todayDate = moment();

  executions.forEach(execution => {
    const executionDate = moment(execution?.startAt.toDate());
    const endDate = moment(execution?.endAt.toDate());

    if (executionDate.isBefore(todayDate) && endDate.isAfter(todayDate)) {
      activeExecutions.push(execution);
    } else if (executionDate.isAfter(todayDate)) {
      nextExecutions.push(execution);
    }
  });

  return {
    activeExecutions,
    nextExecutions,
  };
};

export const compareExecutions = executions => {
  const pendings = [];
  const expires = [];
  const actives = [];

  const now = new Date();

  executions.forEach(e => {
    const isPending = now < e?.startAt?.toDate();
    const isExpired = now > e?.endAt?.toDate();

    if (isPending) {
      pendings.push(e);
      return;
    }
    if (isExpired) {
      expires.push(e);
      return;
    }
    // Else is Active
    actives.push(e);
  });

  actives.sort((a, b) => a.endAt.localeCompare(b.endAt));
  pendings.sort((a, b) => a.endAt.localeCompare(b.endAt));
  expires.sort((a, b) => a.endAt.localeCompare(b.endAt));

  return {
    active: actives,
    expired: expires,
    pending: pendings,
  };
};

export const calculateStatus = ({
  now,
  startTz,
  endTime,
  hasExtraTime,
  endTimeWithExtra,
  isCompleteAll,
  usersAssigned,
  isIncomplete,
}) => {
  if (now >= startTz.getTime() && now <= endTime) {
    // in progress
    if (isCompleteAll) {
      return EXECUTION_STATUS.completed;
    }
    return !usersAssigned ? EXECUTION_STATUS.unassigned : EXECUTION_STATUS.active;
  }
  if (now > endTime) {
    // With delay - with extra time
    if (hasExtraTime && now <= endTimeWithExtra) {
      if (isCompleteAll) {
        return EXECUTION_STATUS.completed;
      }
      return !usersAssigned ? EXECUTION_STATUS.unassigned : EXECUTION_STATUS.delayed;
    }
    if (isCompleteAll) {
      return EXECUTION_STATUS.completed;
    }
    if (isIncomplete) {
      // incomplete
      return EXECUTION_STATUS.incomplete;
    }
    return EXECUTION_STATUS.expired;
  }
  // inactive
  return EXECUTION_STATUS.inactive;
};

/**
 * getUniqueExecutionsAccordingToExecutionUpdated
 * @param {Array} executionsParsed
 * @returns {Array}
 * @description This function receives an array of executions and returns an array of unique executions ,
 *  since when a "Work orders" is updated due to uptating members the BE process has a little delay and the same "Work orders"
 * is returned with the same id but with different startAt, so we need to keep the one with the latest startAt
 */
export const getUniqueExecutionsAccordingToExecutionUpdated = executionsParsed => {
  const elements = executionsParsed.reduce((accum, cur) => {
    // Check if schedule.id already exists in the accumulator
    const exists = Object.values(accum).some(item => item.schedule.id === cur.schedule.id);

    // If it doesn't exist, add it to the accumulator
    if (!exists) {
      accum[cur.id] = cur;
    } else {
      // If it exists, compare the startAt and keep the one with the latest startAt
      const stored = Object.values(accum).find(e => e.schedule.id === cur.schedule.id);

      delete accum[stored.id];
      if (cur.startAt.seconds > stored.startAt.seconds) {
        accum[cur.id] = cur;
      } else {
        accum[stored.id] = stored;
      }
    }

    return accum;
  }, {});

  return Object.values(elements);
};

const PRIORITY_BY_STATUS = {
  [EXECUTION_STATUS.delayed]: 1,
  [EXECUTION_STATUS.unassigned]: 2,
  [EXECUTION_STATUS.active]: 3,
  [EXECUTION_STATUS.completed]: 4,
  [EXECUTION_STATUS.incomplete]: 5,
  [EXECUTION_STATUS.expired]: 6,
  [EXECUTION_STATUS.inactive]: 7,
};
export const calculateNewStatus = (data, triggersUpdated) =>
  data.map(record => {
    const {
      startTz,
      hasExtraTime,
      endTime,
      endTimeWithExtra,
      triggers,
      schedule: { members },
    } = record;
    const now = Date.now();
    const outputTrigger = triggersUpdated[record.id] || triggers;
    const triggerCompletition = getCompletitionData(outputTrigger);
    const isCompleteAll = triggerCompletition?.finishedPercentage === 100;
    const isIncomplete =
      triggerCompletition?.finishedPercentage > 0 && triggerCompletition?.finishedPercentage < 100;
    const usersAssigned = hasUsersAssigned(members);
    const status = calculateStatus({
      now,
      startTz,
      endTime,
      hasExtraTime,
      endTimeWithExtra,
      isCompleteAll,
      usersAssigned,
      isIncomplete,
    });

    return {
      ...record,
      status,
      triggers: outputTrigger,
      triggerCompletition,
      triggersProgress: triggerCompletition?.finishedPercentage || 0,
    };
  });

export const executionFactory = async (data = []) => {
  if (!data) return [];
  const profileTimeZone = get('timeZone') || 'America/Argentina/Buenos_Aires';
  const output = Promise.all(
    data?.map(async record => {
      const {
        organization: { id: orgId } = {},
        division: { id: divId },
        id: execId,
        startAt,
        endAt,
        schedule: {
          schedule,
          expiredAllowedTime,
          isExpiredAllowed,
          triggers: pureTriggers,
          members,
        },
        deliveryState = {},
        viewed = [],
        delivered = [],
        schedule: scheduleData,
        createdAt,
      } = record;

      const startTz = getUserTimeZoneDate(
        startAt.toMillis(),
        schedule.timeZone,
        profileTimeZone,
      ).jsDate;
      const endTz = getUserTimeZoneDate(
        endAt.toMillis(),
        schedule.timeZone,
        profileTimeZone,
      ).jsDate;

      const scheduleCreatedAt = getUserTimeZoneDate(
        scheduleData?.createdAt?.toMillis(),
        schedule.timeZone,
        profileTimeZone,
      ).jsDate;

      const executionCreatedAtTz = getUserTimeZoneDate(
        createdAt.toMillis(),
        schedule.timeZone,
        profileTimeZone,
      ).jsDate;

      const hasExtraTime = isExpiredAllowed || false;
      const endTime = endTz.getTime();
      const timeLeft = Math.max(0, Math.floor((endTime - Date.now()) / (1000 * 60)));

      const endExtraTime = endTime + expiredAllowedTime * 60 * 1000;
      const endTimeWithExtra = hasExtraTime ? endExtraTime : endTime;
      const triggersResponse = await getTriggersByExecution(orgId, divId, execId);
      const triggers = triggersResponse?.length > 0 ? triggersResponse : pureTriggers; // There is some delay when an order is created so we take the pure triggers from execution
      const triggerCompletition = getCompletitionData(triggers);
      const isCompleteAll = triggerCompletition?.finishedPercentage === 100;
      const isIncomplete =
        triggerCompletition?.finishedPercentage > 0 &&
        triggerCompletition?.finishedPercentage < 100;

      const usersAssigned = hasUsersAssigned(members);

      const now = Date.now();
      const status = calculateStatus({
        now,
        startTz,
        endTime,
        hasExtraTime,
        endTimeWithExtra,
        isCompleteAll,
        usersAssigned,
        isIncomplete,
      });

      return {
        ...record,
        divId,
        startTz,
        endTz,
        hasExtraTime,
        endExtraTime,
        timeLeft,
        status,
        triggers,
        triggerCompletition,
        triggersProgress: triggerCompletition?.finishedPercentage || 0,
        endTimeWithExtra,
        endTime,
        deliveryState: {
          viewed: deliveryState?.viewed || viewed || [],
          delivered: deliveryState?.delivered || delivered || [],
        },
        scheduleCreatedAt,
        priority: PRIORITY_BY_STATUS[status],
        executionCreatedAtTz,
      };
    }),
  );

  const outputParsed = await output;
  const uniqueExecutions = getUniqueExecutionsAccordingToExecutionUpdated(outputParsed);

  return uniqueExecutions;
};
