import React from 'react';
import { Tag } from 'antd';
import { EXECUTION_STATUS } from 'constants/commons';
import { useIntl } from 'react-intl';
import styles from './styles.module.less';
import classNames from 'classnames';

/**
 * Renders a tag component representing the execution status.
 *
 * @component
 * @param {string} status - The execution status. Possible values are:
 *   - "active": Represents an active execution status.
 *   - "delayed": Represents a delayed execution status.
 *   - "inactive": Represents an inactive execution status.
 *   - "completed": Represents a completed execution status.
 *   - "incomplete": Represents an incomplete execution status.
 *   - Any other value represents an expired execution status.
 * @returns {JSX.Element} The rendered ExecutionStatus component.
 */

const ExecutionStatus = ({ status, className }) => {
  const intl = useIntl();
  let colorClass = null;
  let executionStatus;

  switch (status) {
    case EXECUTION_STATUS.active:
      colorClass = styles.active;
      executionStatus = intl.formatMessage({ id: 'work.order.status.active' });
      break;
    case EXECUTION_STATUS.delayed:
      colorClass = styles.late;
      executionStatus = intl.formatMessage({ id: 'work.order.status.delayed' });
      break;
    case EXECUTION_STATUS.inactive:
      colorClass = styles.inactive;
      executionStatus = intl.formatMessage({ id: 'work.order.status.inactive' });
      break;
    case EXECUTION_STATUS.completed:
      colorClass = styles.completed;
      executionStatus = intl.formatMessage({ id: 'work.order.status.completed' });
      break;
    case EXECUTION_STATUS.incomplete:
      colorClass = styles.incomplete;
      executionStatus = intl.formatMessage({ id: 'work.order.status.incomplete' });
      break;
    case EXECUTION_STATUS.unassigned:
      colorClass = styles.unassigned;
      executionStatus = intl.formatMessage({ id: 'work.order.status.unassigned' });
      break;
    default:
      colorClass = styles.expired;
      executionStatus = intl.formatMessage({ id: 'work.order.status.expired' });
      break;
  }

  return (
    <Tag className={classNames(colorClass, styles.tagStatus, className)}>{executionStatus}</Tag>
  );
};

export default React.memo(ExecutionStatus);
