import { db } from 'firebase/firebase';
import { doc, getDoc } from 'firebase/firestore';
import * as fb from 'firebase/firestore';
import { getDocData } from 'packages/utils';
import { useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { errorNotification } from 'appRedux/actions';
import { SUBSCRIPTIONS_PAYLOAD } from '../../../users/screens/Notifications/constants';
import { deleteDuplicated, generateDelay } from '../../functions';

const useGetSubscriberByNotification = ({ user, body, onTotalEventTypes, dependencies = [] }) => {
  const [subscriber, setSubscriber] = useState({});
  const [fetching, setFetching] = useState(false);
  const dispatch = useDispatch();

  const { orgId, type, entityId, divisionId } = body;
  const userId = user?.id;
  const hasBody = Object.keys(body).length > 0;
  const hasSubscriber = Object.keys(subscriber).length > 0;
  const avoidSetNotification = Boolean(user.divisions[divisionId]) || false;

  const fetch = useCallback(async () => {
    setFetching(true);

    await generateDelay(1000);

    if (!hasBody || !userId) return;

    const document = getDocData(
      await getDoc(
        doc(
          db,
          'organizations',
          orgId,
          'notification_subscriptions',
          type,
          'entities',
          entityId,
          'subscribers',
          userId,
        ),
      ),
    );
    if (!document) {
      setSubscriber({});
    } else {
      setSubscriber(document);
    }

    setFetching(false);
  }, [entityId, hasBody, orgId, type, userId]);

  const handleActionReport = useCallback(
    async (pAction = '') => {
      if (!avoidSetNotification) return;

      setFetching(true);
      const action = hasSubscriber ? 'deleteDoc' : 'setDoc';
      const process = pAction || action;
      const execute = fb[process];
      const newDocRef = doc(
        db,
        'organizations',
        orgId,
        'notification_subscriptions',
        type,
        'entities',
        entityId,
        'subscribers',
        userId,
      );
      const newId = newDocRef.id;

      try {
        await execute(newDocRef, {
          divId: divisionId,
          id: newId,
          createdAt: fb.serverTimestamp(),
          updatedAt: fb.serverTimestamp(),
          subscriptions: SUBSCRIPTIONS_PAYLOAD,
          user,
        });

        if (onTotalEventTypes) {
          if (action === 'deleteDoc') {
            onTotalEventTypes(prev => {
              const newArr = prev.filter(item => item !== entityId);
              return deleteDuplicated(newArr);
            });
          } else if (action === 'setDoc') {
            onTotalEventTypes(prev => deleteDuplicated([...prev, entityId]));
          }
        }

        await fetch();
        setFetching(false);
      } catch (e) {
        dispatch(errorNotification(e.message || e));
        setFetching(false);
      }
    },
    [
      avoidSetNotification,
      dispatch,
      divisionId,
      entityId,
      fetch,
      hasSubscriber,
      onTotalEventTypes,
      orgId,
      type,
      user,
      userId,
    ],
  );

  // Load subscribers
  useEffect(() => {
    fetch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fetch, ...dependencies]);
  return {
    subscriber,
    hasSubscriber,
    fetching,
    handleActionReport,
  };
};

export default useGetSubscriberByNotification;
