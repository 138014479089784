import React from 'react';
import styles from './styles.module.less';
import classNames from 'classnames';

/**
 * A progress bar component that displays the progress percentage and status.
 *
 * @component
 * @param {number} percentage - The progress percentage (0-100).
 * @param {string} [status=0] - The status of the progress bar. Possible values are 'delayed', 'inactive', 'incomplete', 'completed', 'expired', and 'active'.
 * @returns {JSX.Element} The rendered progress bar component.
 */

const ProgressBar = ({ percentage, status, className }) => {
  const auxStatus = {
    delayed: styles.delayed,
    inactive: styles.inactive,
    incomplete: styles.incomplete,
    completed: styles.complete,
    expired: styles.expired,
    active: styles.active,
    unassigned: styles.active,
  };

  const getStatus = () => {
    const progressBarStyle = { width: `${percentage}%` };
    const statusClassName = auxStatus[status || 'inactive'];

    return (
      <div className={classNames(styles.status, className, statusClassName)}>
        {percentage < 100 && <div className={styles.progressBar} style={progressBarStyle} />}
        <span>{percentage.toFixed()}%</span>
      </div>
    );
  };

  return <div className={styles.progressItemContainer}>{getStatus()}</div>;
};

ProgressBar.Quantity = ({ expected, finished, status, className }) => {
  const auxStatus = {
    delayed: styles.delayed,
    inactive: styles.inactive,
    incomplete: styles.incomplete,
    completed: styles.complete,
    expired: styles.expired,
    active: styles.active,
  };

  const getStatus = () => {
    const percentage = (finished / expected) * 100;
    const progressBarStyle = { width: `${percentage}%` };
    const statusClassName = auxStatus[status || 'inactive'];

    return (
      <div className={classNames(styles.status, className, statusClassName)}>
        {percentage < 100 && <div className={styles.progressBar} style={progressBarStyle} />}
        <span>
          {finished}/{expected}
        </span>
      </div>
    );
  };

  return <div className={styles.progressItemContainer}>{getStatus()}</div>;
};

export default ProgressBar;
