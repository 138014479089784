import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';

import { Form, Pagination } from 'antd';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { db } from 'firebase/firebase';
import safeExecute from 'util/safeExecute';
import IntlMessages from 'util/IntlMessages';
import MembersForm from 'containers/MembersForm';
import { get, set } from 'packages/utils/storage';
import BoxContainer from 'components/BoxContainer';
import { SCHEDULE_TYPES } from 'constants/commons';
import FilterContainer from 'components/FilterContainer';
import Title from 'components/BoxContainer/components/Title';
import WorkOrderInfo from 'components/WorkOrders/WorkOrderInfo';
import { saveMembers } from 'packages/workOrders/workOrderService';
import { collection, orderBy, query, where } from 'firebase/firestore';
import WorkOrderFilter from 'packages/workOrders/components/WorkOrderFilter';
import { calculateNewStatus, executionFactory } from 'packages/utils/functions/executions';
import { useTriggersByExecutionsRt } from 'packages/utils/hooks/useTriggersByExecutionsRt';
import {
  ALLOWED_ROLES,
  getDivsWithAccess,
  useFirestoreRealtimeBatchedPagination,
  useToggle,
} from 'packages/utils';
import {
  getRouteToDetails,
  INIT_FILTERS,
  PAGE_SIZE,
  PATHS,
  sortingExecutions,
  STATUS_ALLOWED_TO_REFRESH,
} from 'packages/workOrders/constants';
import ConfirmUpdateMembers from 'packages/workOrders/components/ConfirmUpdateMembers';

const WorkOrderList = () => {
  const workOrdersRef = useRef();
  const navigate = useHistory();
  const [membersForm] = Form.useForm();

  const selectedDivsIds = useSelector(({ divisions }) => divisions.selector.ids);

  const orgId = useSelector(state => state.organizations.organization.id);
  const userOrgAccess = useSelector(({ user }) => user.access.data?.claims.org[orgId]);
  const userOrgDivisions = useSelector(({ divisions }) => divisions.assigned.data.divisions);
  const allowedDivs = getDivsWithAccess(
    ALLOWED_ROLES.ORGANIZATIONS.DIVISIONS.SCHEDULES.LIST,
    userOrgAccess,
    userOrgDivisions,
  );
  const [currentPage, setCurrentPage] = useState(1);
  const [filters, setFilters] = useState(get('workOrdersFilters') || INIT_FILTERS);
  const [workOrderList, setWorkOrderList] = useState([]);
  const [workOrdersFull, setWorkOrderFull] = useState([]);
  const [membersFormVisible, setMembersFormVisible] = useState(false);
  const [loadingToggle, setLoadingToggle] = useToggle(false);
  const [confirmUpdateMembers, onConfirmUpdateMembers] = useToggle(false);

  const allowedDivsIds = allowedDivs.map(d => d.id);
  const allowedSelectedDivs = selectedDivsIds.filter(divId => allowedDivsIds.includes(divId));

  const queryByDivId = {};
  allowedSelectedDivs.forEach(divId => {
    queryByDivId[divId] = query(
      collection(db, 'organizations', orgId, 'divisions', divId, 'executions'),
      orderBy('createdAt', 'desc'),
      where('schedule.type', '==', SCHEDULE_TYPES.task),
    );
  });

  const getDataFiltered = useCallback(
    d =>
      d
        .filter(item => {
          const workOrderName = (item?.schedule?.name || '').toLowerCase();
          return workOrderName.includes(filters.name.toLowerCase());
        })
        .filter(item => {
          const status = item?.status;
          return filters.status.includes(status);
        }),
    [filters.name, filters.status],
  );

  const {
    data: workOrders,
    loading,
    next,
    gotNewData,
    error,
  } = useFirestoreRealtimeBatchedPagination(
    Object.values(queryByDivId),
    [selectedDivsIds],
    PAGE_SIZE,
  );

  const onRefresh = () => {
    if (gotNewData && !loading) {
      next();
    }
  };

  const start = (currentPage - 1) * PAGE_SIZE;
  const end = start + PAGE_SIZE;
  const output = useMemo(() => {
    const dataFiltered = getDataFiltered(workOrdersFull).slice(start, end);

    return sortingExecutions(dataFiltered) || [];
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [end, getDataFiltered, start, workOrdersFull, loading]);

  const refreshByStatus = output.some(record => STATUS_ALLOWED_TO_REFRESH.includes(record.status));
  const totalPages = Math.ceil(output?.length + 1 / PAGE_SIZE);

  const { data: triggersListener, cleanupActiveSubscriptions } = useTriggersByExecutionsRt(output);

  const handlePageChange = page => {
    cleanupActiveSubscriptions();
    setCurrentPage(page);
    onRefresh();
    workOrdersRef?.current?.scrollIntoView();
  };

  const onShowMoreByTask = (divisionId, orderId) => {
    navigate.push(getRouteToDetails(divisionId, orderId));
  };

  const onAddMembers = useCallback(
    pData => {
      membersForm.setFieldValue('members', pData.schedule.members);
      membersForm.setFieldValue('divisionId', pData.divId);
      membersForm.setFieldValue('scheduleId', pData.schedule.id);
      membersForm.setFieldValue('timeZone', pData.schedule?.schedule?.timeZone);
      membersForm.setFieldValue('duration', pData.schedule?.duration);

      setMembersFormVisible(true);
    },
    [membersForm],
  );

  const onSafeMembers = useCallback(
    resetCounter => {
      safeExecute(async () => {
        const members = membersForm.getFieldValue('members');
        const divId = membersForm.getFieldValue('divisionId');
        const scheduleId = membersForm.getFieldValue('scheduleId');
        const timeZone = membersForm.getFieldValue('timeZone');
        const duration = membersForm.getFieldValue('duration');

        setLoadingToggle();
        await saveMembers({
          members,
          divId,
          scheduleId,
          orgId,
          timeZone,
          duration,
          resetCounter,
        });
      }).finally(() => {
        setMembersFormVisible(false);
        setLoadingToggle();
        onConfirmUpdateMembers();
      });
    },
    [membersForm, onConfirmUpdateMembers, orgId, setLoadingToggle],
  );

  // NOTE: To control the refresh of the list
  useEffect(() => {
    const fetchData = async () => {
      if (JSON.stringify(workOrders) !== JSON.stringify(workOrderList)) {
        setWorkOrderList(workOrders);
      }
    };

    fetchData();
  }, [workOrderList, workOrders]);

  // NOTE: This must be removed when the backend returns status
  useEffect(() => {
    const recalculateData = async () => {
      const resultado = await executionFactory(workOrderList);
      setWorkOrderFull(sortingExecutions(resultado));
    };

    if (!loading) recalculateData();
  }, [loading, refreshByStatus, workOrderList]);

  // NOTE: This must be removed when the backend returns status
  useEffect(() => {
    const updateWorkOrderStatus = () => {
      const dataUpdated = calculateNewStatus(workOrdersFull, triggersListener);

      const hasStatusChanged =
        dataUpdated.some(updatedItem => {
          const originalItem = workOrdersFull.find(item => item.id === updatedItem.id);
          const hasSomeTriggerChanged = originalItem.triggers.some(trigger => {
            const updatedTrigger = updatedItem.triggers.find(tr => tr.id === trigger.id);
            return (
              trigger?.expectedExecutions !== updatedTrigger?.expectedExecutions ||
              trigger?.finishedExecutions !== updatedTrigger?.finishedExecutions
            );
          });

          // NOTE: Check if status or some executions changed
          return (
            (originalItem && originalItem.status !== updatedItem.status) || hasSomeTriggerChanged
          );
        }) || false;

      if (hasStatusChanged) {
        setWorkOrderFull(dataUpdated);
      }
    };
    updateWorkOrderStatus(); // Call the function immediately
    const interval = setInterval(updateWorkOrderStatus, 30000); // Call the function every minute

    return () => {
      clearInterval(interval); // Clean up the interval when the component is unmounted
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [triggersListener]);

  // NOTE: Save filters in local storage
  useEffect(() => {
    set('workOrdersFilters', filters);
  }, [filters]);

  const outPutSorted = sortingExecutions(output);

  return (
    <BoxContainer>
      <Form.Provider onFormFinish={onConfirmUpdateMembers}>
        <BoxContainer fixed content shadow>
          <FilterContainer
            showHide
            title={<Title.Header value={<IntlMessages id="work.order.actives.title" />} />}
            content={
              <WorkOrderFilter handleFilters={setFilters} filters={filters} onRefresh={onRefresh} />
            }
            actionButtons={[
              {
                label: <IntlMessages id="button.create" />,
                action: () => navigate.push(PATHS.CREATE_ORDER),
                type: 'primary',
              },
            ]}
          />
        </BoxContainer>
        <BoxContainer
          content
          loading={loading || loadingToggle}
          error={error}
          empty={!error && outPutSorted?.length === 0}
        >
          {outPutSorted.map((workOrder, idx) => (
            <React.Fragment key={workOrder.id}>
              <div ref={idx === 0 ? workOrdersRef : null}>
                <WorkOrderInfo
                  onShowMore={() => onShowMoreByTask(workOrder?.divId, workOrder?.id)}
                  onAddMembers={onAddMembers}
                  key={workOrder?.id}
                  workOrder={workOrder}
                />
              </div>
            </React.Fragment>
          ))}
        </BoxContainer>
        <div className="gx-flex-row gx-justify-content-center gx-mt-4">
          <Pagination
            current={currentPage}
            onChange={handlePageChange}
            pageSize={PAGE_SIZE}
            total={totalPages}
          />
        </div>
        {membersFormVisible && (
          <MembersForm
            formName="members"
            isVisible={membersFormVisible}
            onCancel={() => setMembersFormVisible(false)}
            defaultMembers={membersForm.getFieldValue('members')}
            divisionIds={[membersForm.getFieldValue('divisionId')]}
            form={membersForm}
            loading={loadingToggle}
          />
        )}
        {confirmUpdateMembers && (
          <ConfirmUpdateMembers
            handleCancel={onConfirmUpdateMembers}
            isModalOpen={confirmUpdateMembers}
            handleOk={onSafeMembers}
            loading={loadingToggle}
          />
        )}
      </Form.Provider>
    </BoxContainer>
  );
};

export default WorkOrderList;
