import React from 'react';

import { Button, Modal } from 'antd';
import PropTypes from 'prop-types';

import IntlMessages from 'util/IntlMessages';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import Title from 'components/BoxContainer/components/Title';
import styles from './styles.module.less';

const ConfirmUpdateMembers = ({ isModalOpen = false, handleOk, handleCancel, loading }) => (
  <Modal closable={false} title={null} open={isModalOpen} footer={null}>
    <div>
      <div className="gx-flex-row gx-justify-content-center gx-mb-2">
        <div className="gx-flex-column gx-align-items-center">
          <ExclamationCircleOutlined className={styles.warningIcon} />
          <div className="gx-mt-2">
            <Title.SubTitle
              value={<IntlMessages id="work.order.actives.members.confirm.title" />}
            />
          </div>
        </div>
        <div className={styles.description}>
          <IntlMessages id="work.order.actives.members.confirm" />
        </div>
      </div>
      <div className={styles.buttons}>
        <Button disabled={loading} block className="gx-mb-1" onClick={handleCancel}>
          <IntlMessages id="button.cancel" />
        </Button>
        <Button
          disabled={loading}
          block
          className="gx-mb-1"
          type="primary"
          onClick={() => handleOk(false)}
        >
          <IntlMessages id="work.order.actives.members.confirm.keepTime" />
        </Button>
        <Button
          disabled={loading}
          block
          className="gx-mb-1"
          type="primary"
          onClick={() => handleOk(true)}
        >
          <IntlMessages id="work.order.actives.members.confirm.resetTime" />
        </Button>
      </div>
    </div>
  </Modal>
);

ConfirmUpdateMembers.propTypes = {
  isModalOpen: PropTypes.bool,
  handleOk: PropTypes.func.isRequired,
  handleCancel: PropTypes.func.isRequired,
  loading: PropTypes.bool,
};

export default ConfirmUpdateMembers;
