import React from 'react';
import classNames from 'classnames';

export default function MenuIcon({ iconName }) {
  return (
    <div className={classNames('gx-flex-row gx-justify-content-center gx-mr-2')}>
      <span className="material-symbols-outlined">
        {iconName}
      </span>
    </div>
  );
}
