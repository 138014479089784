import React, { createContext } from 'react';

import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import useGetAllSchedules from 'packages/utils/hooks/collections/useGetAllSchedules';
import { removeEventTypeDuplicated } from 'containers/DropDownTriggers/constants';

export const EventsByScheduleContext = createContext();

export const EventsByScheduleProvider = ({ children }) => {
  const [runGetAllSchedules, setRunGetAllSchedules] = React.useState(false);

  const orgId = useSelector(state => state.organizations.organization.id);

  const {
    schedulesByDivisionSelected = [],
    data,
    loading: schedulesLoading = false,
    error = null,
  } = useGetAllSchedules(orgId, [], runGetAllSchedules);

  const getEventTypesFromSchedules = (formId, divId) => {
    const extractTriggersWithDivision = schedule => {
      if (!schedule?.triggers) return [];

      const schTriggers = schedule.triggers.map(trigger => ({
        ...trigger,
        divId: schedule.divId,
      }));

      return schTriggers;
    };

    const filterByFormId = trigger => {
      if (!formId) return true;
      return trigger?.eventType?.id === formId;
    };

    let schedules = data;
    if (divId) {
      schedules = data.filter(schedule => schedule.divId === divId);
    }

    const eventTypes = schedules.flatMap(extractTriggersWithDivision).filter(filterByFormId);
    const uniqueTriggers = removeEventTypeDuplicated(eventTypes);

    return uniqueTriggers || [];
  };

  return (
    <EventsByScheduleContext.Provider
      value={{
        schedules: schedulesByDivisionSelected,
        schedulesLoading,
        error,
        runGetAllSchedules: () => setRunGetAllSchedules(true),
        getEventTypesFromSchedules,
      }}
    >
      {children}
    </EventsByScheduleContext.Provider>
  );
};

EventsByScheduleProvider.propTypes = {
  children: PropTypes.node.isRequired,
};
