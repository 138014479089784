export default function repetitionFactory(date) {
  if (!date) return null;

  return {
    day: [date.date()],
    hour: [date.hours()],
    minute: [date.minutes()],
    month: [date.month() + 1],
    weekDay: [],
    year: [date.year()],
  };
}
