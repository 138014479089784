import { SCHEDULE_TYPES } from 'constants/commons';
import { Timestamp } from 'firebase/firestore';
import moment from 'moment-timezone';
import { COMPONENT_KEY_VALUE_BY_TYPE } from 'packages/utils/constants/eventTypes';

export const BASE_URL = 'work_orders';

export const PATHS = {
  BASE_URL,
  CREATE_ORDER: `${BASE_URL}/create`,
  CREATE_ORDER_BY_TEMPLATE: `${BASE_URL}/create/:divId/:templateId`,
  EDIT_ORDER: `${BASE_URL}/order/:divId/:orderId`,
  DETAILS: `${BASE_URL}/details/:divId/:orderId`,
};

export const FORM_ITEM_LAYOUT = {
  labelCol: {
    span: 6,
  },
  wrapperCol: {
    span: 24,
  },
};

export const FORMS = {
  WORK_ORDER: 'workOrder',
  WORK_FORM: 'work_form',
  TASK_FORM: 'task',
  MEMBERS: 'members',
};

export const MEMBER_TYPES = {
  USER: 'user',
  GROUP: 'group',
};

const STATUS = {
  ACTIVE: 'active',
  COMPLETED: 'completed',
  EXPIRED: 'expired',
  DELAYED: 'delayed',
  INCOMPLETE: 'incomplete',
  UNASSIGNED: 'unassigned',
};

export const WORK_ORDER_STATUS = [
  STATUS.ACTIVE,
  STATUS.COMPLETED,
  STATUS.EXPIRED,
  STATUS.DELAYED,
  STATUS.INCOMPLETE,
  STATUS.UNASSIGNED,
];

export const STATUS_ALLOWED_TO_REFRESH = [STATUS.ACTIVE, STATUS.DELAYED, STATUS.UNASSIGNED];

export const INPUT_TYPES_NOT_ALLOWED = ['video', 'file', 'photo'];
export const WORK_FORM_REGEX = /work_form_(\w+)/;

export const PAGE_SIZE = 25;
export const INIT_FILTERS = {
  name: '',
  status: [STATUS.ACTIVE, STATUS.DELAYED, STATUS.UNASSIGNED],
};
export const TASK_DETAILS_TABS = {
  GENERAL: 'general',
  MEMBERS: 'members',
};

export const getRouteToCreateWorkOrder = () => PATHS.CREATE_ORDER;
export const getRouteToDetails = (divId, orderId) =>
  PATHS.DETAILS.replace(':divId', divId).replace(':orderId', orderId);
export const getToWorkOrderList = orgId => `/${orgId}/-/${PATHS.BASE_URL}`;

export const getValuesFromDynamicForm = async (forms, validate = true) => {
  const formKeys = Object.keys(forms);

  const allFormValues = {};

  // eslint-disable-next-line no-restricted-syntax
  for (const key of formKeys) {
    if (key.match(WORK_FORM_REGEX)) {
      try {
        // eslint-disable-next-line no-await-in-loop
        if (validate) await forms[key].validateFields();
        const formValues = forms[key].getFieldsValue();
        allFormValues[key] = formValues;
      } catch (error) {
        throw new Error(error);
      }
    }
  }

  return allFormValues;
};

export const formValuesFactory = (formValues, forms) => {
  if (!formValues || !forms) return null;

  const KEY_VALUE_BY_TYPE = {
    text: 'textValue',
    select: 'selectValue',
    tag: 'tagValue',
    number: 'numberValue',
    toggle: 'booleanValue',
    date: 'dateValue',
  };

  return forms.map(form => {
    const formKey = form.id.replace('work_form_', '');

    const updatedComponents = form.components
      .filter(item => !INPUT_TYPES_NOT_ALLOWED.includes(item.type))
      .map(component => {
        const componentValue =
          formValues[`work_form_${formKey}`][component.id][component?.label?.name];

        let val = componentValue;
        if (component.type === 'select') {
          val = [componentValue];
        } else if (component.type === 'date') {
          val = componentValue ? Timestamp.fromMillis(componentValue.valueOf()) : null;
        }

        return {
          ...component,
          [KEY_VALUE_BY_TYPE[component.type]]: val,
        };
      });
    return {
      ...form,
      components: updatedComponents,
    };
  });
};

export const workOrderFactory = ({ template, data }) => {
  const {
    divId,
    startDateOptional,
    startTimeOptional,
    id: templateId,
    name: templateName,
    triggersFollowIndex = false,
  } = template;
  const {
    workOrderName,
    forms,
    triggers,
    startDate,
    timezone,
    startTime,
    members,
    duration,
    isExpiredAllowed,
    expiredAllowedTime,
  } = data;
  // NOTE: We are going to set the time of the startAtTz according to the startTime and startTimeOptional (Template)
  const startAtTz = startDateOptional
    ? moment(startDate).tz(timezone, true)
    : moment().tz(timezone, true);

  if (startTimeOptional) {
    startAtTz.hour(startTime.hour()).minute(startTime.minute());
  } else {
    startAtTz.hour(moment().hour()).minute(moment().minute());
  }
  const endDate = startAtTz.clone().add(duration, 'minutes');
  const endAtTz = moment(endDate).tz(timezone, true);

  if (startDateOptional) {
    startAtTz.seconds(0);
    endAtTz.seconds(0);
  }

  return {
    divId,
    duration,
    members,
    name: workOrderName || template?.name,
    schedule: {
      startAt: Timestamp.fromMillis(startAtTz.valueOf()), // UTC
      endAt: Timestamp.fromMillis(endAtTz.valueOf()), // UTC
      timeZone: timezone,
      repetition: {
        day: [startAtTz.date()],
        hour: [startAtTz.hours()],
        minute: [startAtTz.minutes()],
        month: [startAtTz.month() + 1],
        weekDay: [],
        year: [startAtTz.year()],
      },
    },
    reminders: [],
    triggers,
    forms,
    type: SCHEDULE_TYPES.task,
    isExpiredAllowed,
    expiredAllowedTime,
    taskSummary: {
      id: templateId,
      name: templateName,
    },
    triggersFollowIndex,
  };
};

export const validateTaskForms = async forms => {
  try {
    const formValuesKeys = Object.keys(forms);
    // eslint-disable-next-line no-restricted-syntax
    for (const key of formValuesKeys) {
      if (key.includes('task')) {
        // eslint-disable-next-line no-await-in-loop
        await forms[key].validateFields();
      }
    }
    return true;
  } catch (error) {
    throw new Error(error);
  }
};

export const getValuesFromTaskForms = async (forms, tasks, locations) => {
  const formValues = {};

  // eslint-disable-next-line no-restricted-syntax
  for (const key in forms) {
    if (key.includes('task')) {
      const taskForm = forms[key];
      const taskFormValues = taskForm.getFieldsValue();
      const taskId = key.replace('task_', '');
      formValues[taskId] = taskFormValues;
    }
  }

  const output = tasks.map(task => {
    const newTask = { ...task };
    const locationIdSelected = formValues[task.id][task.id];
    const location = locations.find(loc => loc.id === locationIdSelected);
    newTask.locations = location ? [location] : [];
    // NOTE: remove the locationOptional and locationRequired from the schedule template
    delete newTask.locationOptional;
    delete newTask.locationRequired;

    // NOTE: remove location group field, since when is a work order we don't need it, only we read the locations and select one
    delete newTask?.locationGroup;

    return newTask;
  });

  return output;
};

export const buildQueryWithFilters = queryByDivId => {
  const queryWithFilters = {};
  Object.keys(queryByDivId).forEach(divId => {
    queryWithFilters[divId] = queryByDivId[divId];
  });

  return queryWithFilters;
};

export const taskDetailsFactory = mainData => {
  if (!mainData) return null;

  const {
    schedule,
    schedule: { members },
  } = mainData;

  return {
    general: {
      ...mainData,
      forms: schedule?.forms || [],
    },
    members: {
      users: members?.users || [],
      groups: members?.groups || [],
    },
    eventTypes: [],
    reception: null,
    timeline: null,
  };
};

export const generateNameByForms = (components = []) => {
  const componentsLimited = components?.slice(0, 3); // Take first 3 components
  const componentValues = [];

  // filter the components that are concatenable
  const concatenableComponents = componentsLimited.filter(
    component =>
      component.type === 'text' ||
      component.type === 'select' ||
      component.type === 'tag' ||
      component.type === 'number',
  );

  // we are going to get the values of the components
  // eslint-disable-next-line no-plusplus
  for (let i = 0; i < Math.min(concatenableComponents.length, 3); i++) {
    const currentComponent = concatenableComponents[i];
    const value = currentComponent[COMPONENT_KEY_VALUE_BY_TYPE[currentComponent?.type]];

    if (componentValues.length <= 3) {
      if (typeof value === 'object') {
        const multiValues = value?.map(v => v).join('-');
        componentValues.push(multiValues);
      } else {
        componentValues.push(value);
      }
    }
  }

  const output = componentValues.filter(value => value !== null && value !== undefined);
  const endLabel = output.join('-').replace('--', '-');
  const MAX_END_LABEL_LENGTH = 180;
  const truncatedEndLabel = endLabel.slice(0, MAX_END_LABEL_LENGTH);

  return truncatedEndLabel;
};

export const sortingExecutions = data =>
  data.sort((a, b) => {
    if (a.priority === b.priority) {
      return new Date(a.executionCreatedAtTz) - new Date(b.executionCreatedAtTz);
    }
    return a.priority - b.priority;
  });
