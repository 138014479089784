import moment from 'moment';
import { Button, Col, DatePicker, Form, Input, notification, Row, Select, Switch } from 'antd';

import { ALLOWED_ROLES, getDivsWithAccess, useToggle } from 'packages/utils';
import BoxContainer from 'components/BoxContainer';
import DynamicForm from 'components/Form/DynamicForm';
import Task from 'packages/workOrders/components/Task';
import FilterContainer from 'components/FilterContainer';
import Title from 'components/BoxContainer/components/Title';
import {
  FORM_ITEM_LAYOUT,
  FORMS,
  formValuesFactory,
  generateNameByForms,
  getValuesFromDynamicForm,
  getValuesFromTaskForms,
  INPUT_TYPES_NOT_ALLOWED,
  PATHS,
  validateTaskForms,
  workOrderFactory,
} from 'packages/workOrders/constants';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { useParams, useHistory } from 'react-router-dom';
import {
  getScheduleTemplateById,
  getScheduleTemplates,
} from 'util/firebase-operations/scheduleTemplates/get';
import IntlMessages from 'util/IntlMessages';
import useGetAllLocations from 'packages/utils/hooks/collections/useGetAllLocations';
import { UsersGroupsWidget } from 'components/Users';
import MembersListDetail from 'components/MemberList/MembersListDetail';
import DropDownDivisions from 'components/DropDownDivision';
import { createSchedule } from 'util/firebase-operations/schedule/create';
import { sendErrorToSentry } from 'util/sentry/utils';
import DurationPicker from 'packages/schedules/components/DurationPicker';
import MembersForm from 'containers/MembersForm';
import styles from './styles.module.less';

const { Item } = Form;
const WorkOrder = () => {
  const navigate = useHistory();
  const [form] = Form.useForm();
  const [memberForm] = Form.useForm();
  const [membersForm] = Form.useForm();
  const divIdRef = useRef(null);
  const workNameGeneratedRef = useRef('');
  const formContainerRef = useRef([]);
  formContainerRef.current = formContainerRef.current || [];

  const intl = useIntl();
  const { divId: divisionByTemplate, templateId: templateIdByParam } = useParams();
  const { id: orgId } = useSelector(({ organizations }) => organizations.organization);
  const userOrgAccess = useSelector(({ user }) => user.access.data?.claims.org[orgId]);
  const userOrgDivisions = useSelector(({ divisions }) => divisions.assigned.data.divisions);

  const [template, setTemplate] = useState(null);
  const [membersFormVisible, membersFormToggle] = useToggle(false);
  const [members, setMembers] = useState({ users: [], groups: [] });
  const [loading, setLoading] = useState(false);
  const [fetchingTemplate, fetchingTemplateToggle] = useToggle(false);
  const [templateId, setTemplateId] = useState(templateIdByParam);
  const [templates, setTemplates] = useState([]);
  const [generaAutoName, onGenerateAutoName] = useToggle(true); // NOTE: all related to auto generate named is in order to be able to generate a name based on the forms

  const mainLoading = fetchingTemplate || loading;
  const now = moment();
  const divisionId = divIdRef.current || divisionByTemplate;
  const fromWorkOrderView = !divisionByTemplate && !templateIdByParam;

  const allowedDivisions = getDivsWithAccess(
    ALLOWED_ROLES.ORGANIZATIONS.DIVISIONS.TRIGGERS.CREATE,
    userOrgAccess,
    userOrgDivisions,
  );

  const { data: locations } = useGetAllLocations({
    organizationId: orgId,
    divisionId: template?.divId,
  });
  const onFormFinish = async (name, { values, forms }) => {
    try {
      // MEMBERS
      if (name === FORMS.MEMBERS) {
        setMembers(forms[FORMS.MEMBERS].getFieldValue('members'));
        membersFormToggle();
        return;
      }

      if (name === FORMS.WORK_ORDER) {
        // NOTE: Here we are going to process the form data from the dynamic form
        const formsData = await getValuesFromDynamicForm(forms);
        const newForms = formValuesFactory(formsData, template.forms);

        // TASKS
        let taskFormsData = null;
        await validateTaskForms(forms); // Check if some field is required
        taskFormsData = await getValuesFromTaskForms(forms, template.triggers, locations);

        // MEMBERS
        await membersForm.validateFields();

        const workOrderBody = workOrderFactory({
          template,
          data: {
            ...values,
            forms: newForms,
            triggers: taskFormsData,
            members,
            isExpiredAllowed: template.isExpiredAllowed,
            expiredAllowedTime: template.expiredAllowedTime,
          },
        });
        setLoading(true);
        await createSchedule({ data: workOrderBody, orgId, divId: template.divId });

        notification.success({
          message: intl.formatMessage({ id: 'general.save.successful.message' }),
          placement: 'topRight',
        });
        navigate.push(`/${orgId}/-/${PATHS.BASE_URL}`);
      }
    } catch (error) {
      setLoading(false);
      sendErrorToSentry(error, { functionName: 'onFormFinish' });
      notification.error({
        message: intl.formatMessage({ id: 'general.save.error.message' }),
      });
    }
  };

  const handleGenerateAutoName = async (_, info) => {
    const formsData = await getValuesFromDynamicForm(info.forms, false);
    const newForms = formValuesFactory(formsData, template.forms);

    const components = newForms.reduce((acum, cur) => {
      const updatedAcum = [...acum, ...cur.components];
      return updatedAcum;
    }, []);

    formContainerRef.current = components;
    const endLabel = generateNameByForms(components);
    workNameGeneratedRef.current = endLabel;

    if (generaAutoName) {
      form.setFieldValue('workOrderName', endLabel);
    }
  };

  const onAutoGenerateName = checked => {
    if (!checked) {
      form.setFieldValue('workOrderName', null);
      onGenerateAutoName();
    } else {
      onGenerateAutoName();
      form.setFieldValue('workOrderName', workNameGeneratedRef.current);
    }
  };

  const onRemoveMember = userId => {
    const memberToBeRemoved = [...members.groups, ...members.users];
    const usersFiltered = memberToBeRemoved.filter(usr => usr.id !== userId);

    const usersAndGroups = usersFiltered.reduce(
      (acc, val) => {
        // eslint-disable-next-line no-prototype-builtins
        const key = val.hasOwnProperty('users') ? 'groups' : 'users';
        acc[key].push(val);
        return acc;
      },
      { users: [], groups: [] },
    );

    setMembers(usersAndGroups);
  };

  const clearForm = () => {
    // clear form
    form.resetFields();
    membersForm.resetFields();
    setTemplate(null);
    setTemplateId(null);
  };

  const getTemplates = useCallback(
    async divId => {
      if (divId) {
        setLoading(true);
        const response = await getScheduleTemplates(orgId, divId);
        setLoading(false);
        setTemplates(response);
      }
    },
    [orgId],
  );
  const templateOptions = useMemo(
    () =>
      templates.map(t => ({
        label: t.name,
        value: t.id,
      })),
    [templates],
  );

  useEffect(() => {
    if (!templateId) return;

    const fetchData = async () => {
      try {
        fetchingTemplateToggle();
        const response = await getScheduleTemplateById(orgId, divisionId, templateId);

        // Set data into form
        form.setFieldsValue({
          workOrderName: response.name,
          startDate: now,
          startTime: now,
          duration: response?.duration || 60,
          timezone: response.timezone,
        });
        setTemplate(response);
        fetchingTemplateToggle();
      } catch (error) {
        fetchingTemplateToggle();
        notification.error({
          message: <IntlMessages id="work.order.error.template.message" />,
        });
      }
    };

    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [templateId]);

  const countUsersAndGroups = () => {
    const userCount = members.users.length;
    const groupCount = members.groups.reduce((total, group) => total + group.users.length, 0);
    const totalUsers = userCount + groupCount;
    return totalUsers;
  };

  const formsAllowed = useMemo(
    () =>
      template?.forms?.map(record => {
        const components = record?.components?.filter(
          component => !INPUT_TYPES_NOT_ALLOWED.includes(component?.type),
        );

        return {
          ...record,
          components,
        };
      }) || [],
    [template?.forms],
  );

  return (
    <BoxContainer>
      <BoxContainer content fixed shadow>
        <FilterContainer
          goBack={() => navigate.goBack()}
          title={<Title.Header value={<IntlMessages id="work.order.form.title" />} />}
          actionButtons={[
            {
              label: <IntlMessages id="form.save" />,
              type: 'primary',
              action: form.submit,
              disabled: mainLoading,
            },
          ]}
        />
      </BoxContainer>
      <BoxContainer content loading={mainLoading}>
        {fromWorkOrderView && (
          <Row gutter={16}>
            <Col xs={24} xl={12} xxl={12}>
              <Title.LabelForm
                required
                className="gx-guarnic-pb-1"
                value={<IntlMessages id="work.order.form.workOrder.division.label" />}
              />
              <DropDownDivisions
                options={allowedDivisions}
                onChange={val => {
                  clearForm();
                  getTemplates(val);
                  divIdRef.current = val;
                }}
                value={divisionId}
                defaultValue={divisionId}
              />
            </Col>
            <Col xs={24} xl={12} xxl={12}>
              <Title.LabelForm
                required
                className="gx-guarnic-pb-1"
                value={<IntlMessages id="work.order.form.workOrder.template.label" />}
              />
              <Select
                showSearch
                className="gx-w-100"
                optionFilterProp="label"
                options={templateOptions}
                onChange={val => {
                  setTemplateId(val);
                }}
                value={templateId}
              />
            </Col>
          </Row>
        )}
        {template && (
          <Form.Provider onFormChange={handleGenerateAutoName} onFormFinish={onFormFinish}>
            <Form className="gx-mt-2" form={form} {...FORM_ITEM_LAYOUT} name={FORMS.WORK_ORDER}>
              <Row gutter={16}>
                <Col xs={24} xl={12} xxl={12}>
                  <Title.LabelForm
                    required
                    className="gx-guarnic-pb-1"
                    value={<IntlMessages id="work.order.form.workOrder.label" />}
                  />
                  <Form.Item
                    rules={[
                      { required: true, message: <IntlMessages id="form.required.msg" /> },

                      {
                        max: 180,
                        message: <IntlMessages id="form.maxLength.msg" values={{ amount: 180 }} />,
                      },
                    ]}
                    name="workOrderName"
                  >
                    <Input disabled={generaAutoName} />
                  </Form.Item>
                </Col>
                <Col xs={24} xl={12} xxl={12}>
                  <Title.LabelForm
                    className="gx-guarnic-pb-1"
                    value={<IntlMessages id="work.order.form.workOrder.generateName" />}
                  />
                  <Switch
                    onChange={onAutoGenerateName}
                    checked={generaAutoName}
                    className="gx-mt-sm-2 gx-mt-0"
                  />
                </Col>
              </Row>
              <Row gutter={[8, 8]}>
                <Col span={24} className="gx-pt-2 gx-guarnic-pb-2">
                  <span className="gx-guarnic-label">
                    <IntlMessages id="work.order.section.whenShouldOccur.title" />
                  </span>
                </Col>
                {template?.startDateOptional && (
                  <Col xs={24} xl={12} xxl={12}>
                    <Title.LabelForm
                      required
                      value={<IntlMessages id="work.order.form.startDate.label" />}
                      className="gx-guarnic-pb-1"
                    />
                    <Item name="startDate" required>
                      <DatePicker
                        getPopupContainer={trigger => trigger.parentNode}
                        allowClear={false}
                        className="gx-w-100"
                        disabledDate={current =>
                          current && current < moment(now.format('YYYY-MM-DD'), 'YYYY-MM-DD')
                        }
                        inputReadOnly
                      />
                    </Item>
                  </Col>
                )}
                {template?.startTimeOptional && (
                  <Col xs={24} xl={12} xxl={12}>
                    <Title.LabelForm
                      required
                      value={<IntlMessages id="work.order.form.startTime.label" />}
                      className="gx-guarnic-pb-1"
                    />
                    <Item name="startTime" required>
                      <DatePicker.TimePicker
                        getPopupContainer={trigger => trigger.parentNode}
                        className="gx-w-100"
                        allowClear={false}
                        format="HH:mm"
                        inputReadOnly
                        minuteStep={1}
                        render
                        showNow={false}
                        showSecond={false}
                        suffixIcon={null}
                      />
                    </Item>
                  </Col>
                )}
                <Col xs={24} xl={12} xxl={12}>
                  <Title.LabelForm
                    required
                    className="gx-guarnic-pb-1"
                    value={<IntlMessages id="work.order.form.duration.label" />}
                  />
                  <Item
                    name="duration"
                    rules={[
                      {
                        required: true,
                        message: intl.formatMessage({
                          id: 'form.required',
                        }),
                      },
                    ]}
                  >
                    <DurationPicker
                      disabled={!template?.durationReadonly || false}
                      getPopupContainer={trigger => trigger.parentNode}
                      className="gx-w-100 gx-m-0"
                      minuteStep={1}
                      placeholder="00:00"
                    />
                  </Item>
                </Col>
                <Col xs={24} xl={12} xxl={12}>
                  <Title.LabelForm
                    required
                    value={<IntlMessages id="work.template.form.timeZone.label" />}
                    className="gx-guarnic-pb-1"
                  />
                  <Item name="timezone" required>
                    <Select
                      getPopupContainer={trigger => trigger.parentNode}
                      id="timeZone"
                      showSearch
                      disabled={!template?.timezoneReadonly || false}
                    >
                      {moment.tz.names().map(tz => (
                        <Select.Option key={tz} value={tz}>
                          {tz} - <em>GMT {moment.tz(tz).format('Z')}</em>
                        </Select.Option>
                      ))}
                    </Select>
                  </Item>
                </Col>
              </Row>
            </Form>
            <Row gutter={[8, 8]}>
              {formsAllowed &&
                formsAllowed.map(record => (
                  <React.Fragment key={record.id}>
                    <Col span={24}>
                      <DynamicForm
                        {...FORM_ITEM_LAYOUT}
                        formName={`${FORMS.WORK_FORM}_${record?.id}`}
                        formData={record}
                        formTitle={record?.name}
                      />
                    </Col>
                  </React.Fragment>
                ))}
            </Row>
            <Row gutter={[8, 8]}>
              <Col span={24} className="gx-pt-2 gx-guarnic-pb-2">
                <span className="gx-guarnic-label">
                  <IntlMessages id="work.order.section.tasks.title" />
                </span>
              </Col>
              <Col span={24}>
                {template?.triggers.map(trigger => (
                  <Task
                    hasOrder={template?.triggersFollowIndex || false}
                    key={trigger.id}
                    trigger={trigger}
                    locations={locations}
                  />
                ))}
              </Col>
            </Row>
            <Row gutter={[8, 8]}>
              <Col span={24}>
                <div className="gx-flex-row gx-justify-content-between gx-guarnic-pb-1">
                  <div className="gx-pt-2">
                    <Title.LabelForm
                      value={
                        <span className="gx-guarnic-label">
                          <IntlMessages id="work.order.sectionTitle.whoShouldComply" />
                        </span>
                      }
                    />
                  </div>
                  <div className={styles.usersGroupsContainer}>
                    <UsersGroupsWidget
                      groups={members.groups.length}
                      users={members.users.length}
                    />
                  </div>
                </div>
                <Form form={membersForm} {...FORM_ITEM_LAYOUT} name={FORMS.MEMBERS}>
                  <Item
                    name="members"
                    rules={[
                      {
                        validator: async () => {
                          const total = countUsersAndGroups();
                          if (total === 0) Promise.resolve();

                          if (total > 0 && total < template.minUsers) {
                            return Promise.reject(
                              intl.formatMessage(
                                { id: 'work.order.form.minUsers.message' },
                                { minUsers: template.minUsers },
                              ),
                            );
                          }
                          return Promise.resolve();
                        },
                      },
                    ]}
                  >
                    <MembersListDetail
                      members={[...members.users, ...members.groups]}
                      onRemove={onRemoveMember}
                    />
                  </Item>
                  <Button
                    className="gx-mb-0"
                    type="primary"
                    block
                    onClick={() => membersFormToggle()}
                  >
                    <IntlMessages id="button.add" />
                  </Button>
                </Form>
              </Col>
            </Row>
            {divisionId && membersFormVisible && (
              <MembersForm
                formName={FORMS.MEMBERS}
                isVisible={membersFormVisible}
                onCancel={() => membersFormToggle()}
                defaultMembers={members}
                divisionIds={[divisionId]}
                form={memberForm}
              />
            )}
          </Form.Provider>
        )}
      </BoxContainer>
    </BoxContainer>
  );
};

export default WorkOrder;
