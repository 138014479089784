import { db } from 'firebase/firebase';
import { doc, Timestamp, updateDoc } from 'firebase/firestore';
import moment from 'moment';
import repetitionFactory from 'packages/utils/functions/schedule/repetitionFactory';
import { createScheduleOptions } from 'util/firebase-operations/schedule/create';

const SCHEDULE__NEXT_NOW_EXECUTION = {
  NEXT: 'NEXT',
  NOW: 'NOW',
};
export const saveMembers = async ({
  members,
  divId,
  scheduleId,
  orgId,
  timeZone,
  duration,
  resetCounter = false,
}) => {
  const docRef = doc(db, 'organizations', orgId, 'divisions', divId, 'schedules', scheduleId);
  const newAtartAtTz = moment(new Date()).tz(timeZone, true).seconds(0);
  const newEndDate = newAtartAtTz.clone().add(duration, 'minutes');

  let body = {
    'members.users': members.users,
    'members.groups': members.groups,
  };

  if (resetCounter) {
    body = {
      ...body,
      'schedule.startAt': Timestamp.fromMillis(newAtartAtTz.valueOf()),
      'schedule.endAt': Timestamp.fromMillis(newEndDate.valueOf()),
      'schedule.repetition': repetitionFactory(newAtartAtTz),
    };
  }

  await updateDoc(docRef, body).then(async () => {
    await createScheduleOptions({
      data: {
        action: SCHEDULE__NEXT_NOW_EXECUTION.NOW,
      },
      orgId,
      divId,
      scheduleId,
    });
  });
};
