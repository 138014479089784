import React from 'react';
import { Link } from 'react-router-dom';
import IntlMessages from 'util/IntlMessages';

export default function MenuLabel({ idLabelMessage, className, to }) {
  const Element = Link;

  return to ? (
    <Element to={to} className={className}>
      <IntlMessages id={idLabelMessage} />
    </Element>
  ) : (
    <IntlMessages id={idLabelMessage} className={className} />
  );
}
