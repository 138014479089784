/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useContext, useEffect, useMemo, useRef, useState } from 'react';
import { Button, Col, Form, Input, Row, Select } from 'antd';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import moment from 'moment';
import PropTypes from 'prop-types';

import IntlMessages from 'util/IntlMessages';

import BoxContainer from 'components/BoxContainer';
import Title from 'components/BoxContainer/components/Title';
import FormSelect from 'components/Form/components/FormSelect';
import useGetEventTypes from 'packages/utils/hooks/useGetEventTypes';
import { SlidePanelContext } from 'packages/dashboard/components/SlidePanel/SlidePanelContext';
import { DASHBOARDS_FORMS } from '../../constants';
import styles from './styles.module.less';
import DropDownTriggers from '../../../../../../containers/DropDownTriggers';
import { EventsByScheduleContext } from '../../EventsByScheduleContext';

const { Option } = Select;
const initState = {
  range: {
    isRelative: true,
    relativeAmount: 1,
    dateType: 'day',
    dateRange: [moment().subtract(6, 'month'), moment()],
  },
  interval: 'day', // formerly groupBy
  triggerId: undefined,
  componentId: undefined,
};

const EventsForm = ({ formRef, divId }) => {
  const intl = useIntl();
  const auxEventType = useRef();
  const { boardSelected } = useContext(SlidePanelContext);
  const { getEventTypesFromSchedules, schedulesLoading } = useContext(EventsByScheduleContext);

  const [queryParams, setQueryParams] = useState(initState);
  const [eventType, setEventType] = useState();
  const [trigger, setTrigger] = useState();

  const orgId = useSelector(state => state.organizations.organization.id);
  const eventTypeIdByTriggerSelected = trigger?.eventType?.id || false;

  const eventTypesFromSchedule = getEventTypesFromSchedules(eventType, divId);

  const handleClear = () => {
    setQueryParams(curr => ({ ...curr, triggerId: null, componentId: null }));
    setTrigger(null);
    setEventType(null);

    formRef.current.resetFields();
  };

  const { data: eventTypes, loading: eventTypesLoading } = useGetEventTypes(
    true,
    boardSelected?.divId,
  );

  const mainLoading = schedulesLoading || eventTypesLoading;
  const eventTypesWithNumComponents = useMemo(() => {
    let output = eventTypes?.filter(et =>
      et?.components.some(component => component.type === 'number'),
    );
    if (eventTypeIdByTriggerSelected) {
      output = output.filter(et => et.id === eventTypeIdByTriggerSelected);
    }
    return output;
  }, [eventTypes, eventTypeIdByTriggerSelected]);

  const handleEventTypeChange = et => {
    setEventType(et);
    setQueryParams(curr => ({ ...curr, componentId: undefined }));
  };
  const handleComponentChange = componentId => {
    setQueryParams(curr => ({ ...curr, componentId }));
  };

  const handleTriggerChange = trig => {
    setQueryParams(curr => ({ ...curr, triggerId: trig?.id }));
    setTrigger(trig);
    formRef.current.setFieldValue('triggerId', trig?.id);
  };

  // Can't select dates after today
  const divisionPerEventType = eventTypes?.find(event => event.id === eventType)?.divId || null;

  // aux props
  const auxEventComponents = queryParams.componentId
    ? {
        value: queryParams.componentId,
      }
    : {};

  // Here we are evaluating to if you have a trigger selected we are going to take the eventType of that trigger if we don't  take the eventType selected.
  auxEventType.current =
    eventType || (eventTypeIdByTriggerSelected && eventTypesWithNumComponents.length > 0)
      ? {
          value: eventType || eventTypeIdByTriggerSelected || null,
        }
      : {};

  const hasEventTypeSetted = Object.keys(auxEventType.current).length && auxEventType.current.value;
  // Effects
  useEffect(() => {
    // Reset fields if organization changes
    setQueryParams(initState);
  }, [orgId]);

  return (
    <Form
      labelCol={{ span: 6 }}
      wrapperCol={{ span: 24 }}
      ref={formRef}
      name={DASHBOARDS_FORMS.CHART}
    >
      <BoxContainer content loading={mainLoading}>
        <Row gutter={16}>
          <Col span={24}>
            <Title.LabelForm
              required
              className="gx-guarnic-pb-1"
              value={intl.formatMessage({
                id: 'dashboards.chart.form.title',
              })}
            />
            <Form.Item
              rules={[
                {
                  required: true,
                  message: intl.formatMessage({
                    id: 'form.required',
                  }),
                },
              ]}
              name="title"
            >
              <Input
                placeholder={intl.formatMessage({
                  id: 'dashboards.chart.form.title.placeholder',
                })}
              />
            </Form.Item>
          </Col>
          <Col xs={24} md={8} sm={8} xxl={8}>
            <Title.LabelForm required value={<IntlMessages id="dashboards.chart.form.form" />} />
            <Form.Item
              rules={[
                {
                  required: true,
                  message: intl.formatMessage({
                    id: 'form.required',
                  }),
                },
              ]}
              name="formId"
            >
              <FormSelect
                onChange={val => {
                  handleEventTypeChange(val);
                  formRef.current.setFieldValue('componentId', null);
                  formRef.current.setFieldValue('triggerId', null);
                }}
                options={eventTypesWithNumComponents}
                showSearch
                loading={eventTypesLoading}
                {...auxEventType.current}
              />
            </Form.Item>
          </Col>
          <Col xs={24} md={8} sm={8} xxl={8}>
            <Title.LabelForm
              required
              value={<IntlMessages id="dashboards.chart.form.component" />}
            />
            <Form.Item
              rules={[
                {
                  required: true,
                  message: intl.formatMessage({
                    id: 'form.required',
                  }),
                },
              ]}
              name="componentId"
            >
              <Select
                className={styles.itemFilter}
                dropdownMatchSelectWidth={false}
                optionFilterProp="children"
                placeholder={intl.formatMessage({ id: 'dashboards.chart.form.component' })}
                onChange={handleComponentChange}
                showSearch
                {...auxEventComponents}
              >
                {/* Take only the components that are numbers */}
                {Object.keys(eventTypesWithNumComponents).length > 0 &&
                  hasEventTypeSetted &&
                  eventTypesWithNumComponents
                    .find(event => event?.id === auxEventType.current.value)
                    .components?.filter(({ type }) => type === 'number')
                    .map(({ label: { name }, id = '' }) => (
                      <Option key={id} value={id}>
                        {name}
                      </Option>
                    ))}
              </Select>
            </Form.Item>
          </Col>
          <Col xs={24} md={8} sm={8} xxl={8}>
            <Title.LabelForm value={<IntlMessages id="general.eventTypes" />} />
            <Form.Item name="triggerId">
              <DropDownTriggers
                onChange={handleTriggerChange}
                division={divisionPerEventType}
                eventType={eventType}
                value={trigger?.id}
                extraTriggers={eventTypesFromSchedule}
                disabled={!hasEventTypeSetted}
              />
            </Form.Item>
          </Col>
          <Col className="gx-flex-row gx-justify-content-end gx-mt-4" span={24}>
            <Button onClick={() => handleClear()}>
              <IntlMessages id="button.clear" />
            </Button>
          </Col>
        </Row>
      </BoxContainer>
    </Form>
  );
};

EventsForm.propTypes = {
  formRef: PropTypes.object.isRequired,
  divId: PropTypes.string,
};

export default EventsForm;
