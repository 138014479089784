import React from 'react';

import { Menu } from 'antd';
import { union } from 'lodash';
import { useSelector } from 'react-redux';
import { useRouteMatch } from 'react-router-dom';

import { DownOutlined } from '@ant-design/icons';
import { getRouteToUsersHome } from 'packages/users';
import { PATHS as GROUPS_PATHS } from 'packages/groups';
import { PATHS as EVENTS_PATHS } from 'packages/events';
import { PATHS as TRIGGERS_PATHS } from 'packages/triggers';
import { PATHS as DASHBOARD_PATHS } from 'packages/dashboard';
import { PATHS as LOCATIONS_PATHS } from 'packages/locations';
import { PATHS as SCHEDULES_PATHS } from 'packages/schedules';
import { PATHS as CONFIG_PATHS } from 'routes/config/constants';
import { PATHS as MONITORING_PATHS } from 'packages/monitoring';
import { PATHS as EVENT_TYPES_PATHS } from 'packages/eventTypes';
import { PATHS as WORK_ORDERS_PATHS } from 'packages/workOrders';
import { ALLOWED_ROLES, hasAnyAllowedRole } from 'packages/utils';
import useRemoteConfig from 'packages/utils/hooks/useRemoteConfig';
import { PATHS as WORK_TEMPLATES_PATHS } from 'packages/workTemplates';
import { PATHS as USER_POSITION_HISTORY_PATHS } from 'packages/reports';
import { useOrganizationsAllowedRC } from 'packages/utils/hooks/remoteConfigs/useOrganizationsAllowedRC';

import SidebarLogo from './SidebarLogo';
import styles from './styles.module.less';
import MenuLabel from './components/MenuLabel';
import MenuIcon from './components/MenuIcon/index.js';
import OrganizationSelector from './components/OrganizationSelector';

const SideBarMenu = React.memo(() => {
  let { url } = useRouteMatch();
  const { id: orgId } = useSelector(({ organizations }) => organizations.organization);
  const userOrgAccess = useSelector(({ user }) => user.access.data?.claims.org[orgId]);
  const userOrgDivisions = useSelector(({ divisions }) => divisions.assigned.data.divisions);

  const { value: activeUserAlloweds } = useRemoteConfig('active_user_view');
  const { organizationsAllowed } = useOrganizationsAllowedRC({
    remoteConfigName: 'location_alerts_view',
    redirect: false,
  });

  const getItem = ({
    label,
    key,
    icon,
    children,
    type,
    allowedRoles,
    accessByRemoteConfig = true,
    disabled,
  }) => {
    const hasAccess = hasAnyAllowedRole(allowedRoles, userOrgAccess, userOrgDivisions);
    if (!hasAccess || !accessByRemoteConfig) {
      return null;
    }
    return {
      key,
      icon,
      children,
      label,
      type,
      disabled,
    };
  };

  const items = [
    getItem({
      label: <MenuLabel idLabelMessage="general.home" to={url} />,
      key: 'home',
      icon: <MenuIcon iconName="home" />,
    }),
    getItem({
      label: (
        <MenuLabel
          idLabelMessage="sidebar.configuration.timeline"
          to={`${url}/${EVENTS_PATHS.BASE_URL}`}
        />
      ),
      key: 'timeline',
      allowedRoles: ALLOWED_ROLES.ORGANIZATIONS.EVENTS_SUMMARY.LIST,
      icon: <MenuIcon iconName="timeline" />,
    }),
    getItem({
      label: (
        <MenuLabel
          idLabelMessage="sidebar.menu.active.work.orders"
          to={`${url}/${WORK_ORDERS_PATHS.BASE_URL}`}
        />
      ),
      key: 'work-orders',
      allowedRoles: ALLOWED_ROLES.ORGANIZATIONS.DIVISIONS.WORK_ORDER.LIST,
      icon: <MenuIcon iconName="desktop_mac" />,
    }),
    getItem({
      label: (
        <MenuLabel
          idLabelMessage="sidebar.dashboards.schedules"
          to={`${url}/${DASHBOARD_PATHS.SCHEDULES}`}
        />
      ),
      key: 'schedule-report',
      icon: <MenuIcon iconName="add_chart" />,
    }),
    getItem({
      label: (
        <MenuLabel
          idLabelMessage="sidebar.dashboards.actives"
          to={`${url}/${DASHBOARD_PATHS.ACTIVE_SCHEDULES}`}
        />
      ),
      key: 'share',
      icon: <MenuIcon iconName="share" />,
    }),
    getItem({
      label: (
        <MenuLabel
          idLabelMessage="dashboards.active_users"
          to={`${url}/${DASHBOARD_PATHS.ACTIVE_USER_DASHBOARD}`}
        />
      ),
      key: 'contacts',
      icon: <MenuIcon iconName="contacts" />,
      accessByRemoteConfig: activeUserAlloweds,
    }),
    getItem({
      label: (
        <MenuLabel
          idLabelMessage="sidebar.dashboards.metrics"
          to={`${url}/${DASHBOARD_PATHS.EVENTS}`}
        />
      ),
      key: 'show_chart',
      icon: <MenuIcon iconName="show_chart" />,
    }),
    getItem({
      label: (
        <MenuLabel
          idLabelMessage="sidebar.positionHistory.entry"
          to={`${url}/${USER_POSITION_HISTORY_PATHS.USER_POSITIONS_HISTORY}`}
        />
      ),
      key: 'history',
      icon: <MenuIcon iconName="history" />,
    }),
    { type: 'divider' },
    getItem({
      allowedRoles: ALLOWED_ROLES.ORGANIZATIONS.DIVISIONS.SCHEDULES.LIST,
      label: (
        <MenuLabel
          idLabelMessage="sidebar.menu.schedules"
          to={`${url}/${SCHEDULES_PATHS.BASE_URL}`}
        />
      ),
      key: 'calendar_today',
      icon: <MenuIcon iconName="calendar_today" />,
    }),
    getItem({
      allowedRoles: ALLOWED_ROLES.ORGANIZATIONS.DIVISIONS.WORK_TEMPLATES.LIST,
      label: (
        <MenuLabel
          idLabelMessage="sidebar.menu.work.templates"
          to={`${url}/${WORK_TEMPLATES_PATHS.BASE_URL}`}
        />
      ),
      key: 'task_alt',
      icon: <MenuIcon iconName="task_alt" />,
    }),
    getItem({
      allowedRoles: ALLOWED_ROLES.ORGANIZATIONS.DIVISIONS.TRIGGERS.LIST,
      label: (
        <MenuLabel
          idLabelMessage="sidebar.menu.triggers"
          to={`${url}/${TRIGGERS_PATHS.BASE_URL}`}
        />
      ),
      key: 'flash_on',
      icon: <MenuIcon iconName="flash_on" />,
    }),
    getItem({
      allowedRoles: union(
        ALLOWED_ROLES.ORGANIZATIONS.EVENT_TYPES.LIST,
        ALLOWED_ROLES.ORGANIZATIONS.DIVISIONS.EVENT_TYPES.LIST,
      ),
      label: (
        <MenuLabel
          idLabelMessage="sidebar.menu.forms"
          to={`${url}/${EVENT_TYPES_PATHS.BASE_URL}`}
        />
      ),
      key: 'calendar_month',
      icon: <MenuIcon iconName="calendar_month" />,
    }),
    getItem({
      allowedRoles: union(
        ALLOWED_ROLES.ORGANIZATIONS.LOCATIONS.LIST,
        ALLOWED_ROLES.ORGANIZATIONS.DIVISIONS.LOCATIONS.LIST,
      ),
      label: (
        <MenuLabel
          idLabelMessage="sidebar.configuration.locations"
          to={`${url}/${LOCATIONS_PATHS.BASE_URL}`}
        />
      ),
      key: 'location_on',
      icon: <MenuIcon iconName="location_on" />,
    }),
    { type: 'divider' },
    getItem({
      label: <MenuLabel idLabelMessage="sidebar.configuration.organization" />,
      icon: <MenuIcon iconName="business" />,
      children: [
        getItem({
          allowedRoles: ALLOWED_ROLES.ORGANIZATIONS.UPDATE,
          label: (
            <MenuLabel
              idLabelMessage="sidebar.configuration.organization.info"
              to={`${url}/${CONFIG_PATHS.ORGANIZATION}`}
            />
          ),
          key: 'business',
          icon: <MenuIcon iconName="apartment" />,
        }),
        getItem({
          allowedRoles: ALLOWED_ROLES.ORGANIZATIONS.DIVISIONS.GET,
          label: (
            <MenuLabel
              idLabelMessage="sidebar.configuration.divisions"
              to={`${url}/${CONFIG_PATHS.DIVISIONS}`}
            />
          ),
          key: 'map',
          icon: <MenuIcon iconName="map" />,
        }),
        getItem({
          allowedRoles: ALLOWED_ROLES.ORGANIZATIONS.DIVISIONS.GROUPS.LIST,
          label: (
            <MenuLabel
              idLabelMessage="sidebar.configuration.groups"
              to={`${url}/${GROUPS_PATHS.BASE_URL}`}
            />
          ),
          key: 'groups',
          icon: <MenuIcon iconName="groups" />,
        }),
        getItem({
          allowedRoles: ALLOWED_ROLES.ORGANIZATIONS.USERS.LIST,
          label: (
            <MenuLabel
              idLabelMessage="sidebar.configuration.users"
              to={`${url}/${getRouteToUsersHome()}`}
            />
          ),
          key: 'group',
          icon: <MenuIcon iconName="group" />,
        }),
      ],
    }),
    getItem({
      allowedRoles: ALLOWED_ROLES.ORGANIZATIONS.LOCATION_ALERTS.LIST,
      label: (
        <MenuLabel
          idLabelMessage="sidebar.menu.monitoring.location.alerts"
          to={`${url}/${MONITORING_PATHS.LOCATION_ALERTS}`}
        />
      ),
      key: 'motion_sensor_urgent',
      icon: <MenuIcon iconName="motion_sensor_urgent" />,
      accessByRemoteConfig: organizationsAllowed,
    }),
    getItem({
      key: 'version',
      label: <small className="gx-text-white">Version: {SYSTEM.version}</small>,
      disabled: true,
    }),
  ].filter(Boolean);

  return (
    <>
      <SidebarLogo />
      <div className={styles.content}>
        <OrganizationSelector />
        <Menu
          defaultSelectedKeys={['home']}
          mode="inline"
          theme="dark"
          items={items}
          className={styles.sideBarMenu}
          expandIcon={({ isOpen }) => <DownOutlined rotate={isOpen ? 180 : 0} />}
        />
      </div>
    </>
  );
});

export default SideBarMenu;
