import { useSelector } from 'react-redux';
import { ALLOWED_ROLES, getDivsWithAccess } from '../access';

// NOTE: Permitted divisions by organization
const useGetDivisionsAllowed = (roles = ALLOWED_ROLES.ORGANIZATIONS.DIVISIONS.LIST) => {
  const orgId = useSelector(({ organizations }) => organizations.organization.id);
  const selectedDivsIds = useSelector(({ divisions }) => divisions.selector.ids || []);
  const userOrgAccess = useSelector(({ user }) => user.access.data?.claims.org[orgId]);
  const userOrgDivisions = useSelector(({ divisions }) => divisions.assigned.data.divisions);

  const allowedDivs = getDivsWithAccess(roles, userOrgAccess, userOrgDivisions);
  const allowedDivsIds = allowedDivs.map(d => d.id);
  const allowedSelectedDivs = selectedDivsIds.filter(divId => allowedDivsIds.includes(divId));

  return {
    allowedSelectedDivs, // Divisions allowed controlled by division selector
    allowedDivs, // All divisions allowed
    allowedDivsIds, // All divisions allowed ids
  };
};

export default useGetDivisionsAllowed;
