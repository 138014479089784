import { useMemo } from 'react';

import moment from 'moment';

import { db } from 'firebase/firebase';
import { SCHEDULE_TYPES } from 'constants/commons';
import { collection, query, where } from 'firebase/firestore';

import { useSelector } from 'react-redux';
import useGetDivisionsAllowed from '../useGetDivisionsAllowed';
import useFirestoreQueryBatched from '../useFirestoreQueryBatched/useFirestoreQueryBatched';

const useGetAllSchedules = (orgId, divIds = [], run = true) => {
  const divisionIds = divIds.filter(Boolean);
  const { allowedDivsIds } = useGetDivisionsAllowed();
  const selectedDivsIds = useSelector(({ divisions }) => divisions.selector.ids || []);

  const scheduleRefs = useMemo(() => {
    if (!run) {
      return [];
    }

    if (divisionIds.length > 0) {
      return divisionIds.map(divId =>
        query(
          collection(db, 'organizations', orgId, 'divisions', divId, 'schedules'),
          where('type', '==', SCHEDULE_TYPES.schedule),
        ),
      );
    }

    return allowedDivsIds.map(divId =>
      query(
        collection(db, 'organizations', orgId, 'divisions', divId, 'schedules'),
        where('type', '==', SCHEDULE_TYPES.schedule),
      ),
    );
  }, [allowedDivsIds, divisionIds, orgId, run]);

  const { data, loading, error } = useFirestoreQueryBatched(scheduleRefs, []);

  const todayDate = moment();
  const activeSchedules = data.filter(element => {
    const executionDate = moment(element?.schedule?.startAt?.toDate());
    const endDate = moment(element?.schedule?.endAt?.toDate());

    return executionDate.isBefore(todayDate) && endDate.isAfter(todayDate);
  });

  const schedulesByDivisionSelected = data.filter(schedule =>
    selectedDivsIds.includes(schedule.divId),
  );

  return { data, activeSchedules, schedulesByDivisionSelected, loading, error };
};

export default useGetAllSchedules;
