import { Select } from 'antd';
import React from 'react';
import { useSelector } from 'react-redux';
import { collection, orderBy, query } from 'firebase/firestore';
import { db } from 'firebase/firebase';
import styles from './styles.module.less';
import { ALLOWED_ROLES, getDivsWithAccess, useFirestoreQueryBatched } from 'packages/utils';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';
import { removeEventTypeDuplicated } from './constants';

const { Option } = Select;
const DropDownTriggers = React.memo(
  ({
    division = '',
    eventType = '',
    onChange,
    value = undefined,
    extraTriggers = [],
    ...props
  }) => {
    const intl = useIntl();

    const orgId = useSelector(({ organizations }) => organizations.organization.id);
    const selectedDivsIds = useSelector(({ divisions }) => divisions.selector.ids || []);

    const userOrgAccess = useSelector(({ user }) => user.access.data?.claims.org[orgId]);
    const userOrgDivisions = useSelector(({ divisions }) => divisions.assigned.data.divisions);
    const allowedDivs = getDivsWithAccess(
      ALLOWED_ROLES.ORGANIZATIONS.DIVISIONS.LOCATIONS.LIST,
      userOrgAccess,
      userOrgDivisions,
    );
    const allowedDivsIds = allowedDivs.map(d => d.id);
    const allowedSelectedDivs = selectedDivsIds.filter(divId => allowedDivsIds.includes(divId));
    const queriesList = allowedSelectedDivs.map(divId =>
      query(
        collection(db, 'organizations', orgId, 'divisions', divId, 'triggers'),
        orderBy('createdAt'),
      ),
    );
    const { data: triggers = [], loading: triggerLoading } = useFirestoreQueryBatched(queriesList, [
      selectedDivsIds,
    ]);

    let triggersAndExtra = triggers ? [...triggers, ...extraTriggers] : extraTriggers;
    triggersAndExtra = removeEventTypeDuplicated(triggersAndExtra);

    let triggersData = division
      ? triggersAndExtra.filter(trigger => trigger.divId === division) || []
      : triggersAndExtra || [];

    if (eventType) {
      triggersData = triggersData.filter(tr => tr.eventType.id === eventType);
    }

    //Avoid triggers repeated
    triggersData = triggersData.filter(
      (trigger, index, self) => index === self.findIndex(t => t.id === trigger.id),
    );

    const handleChange = triggerId => {
      if (!triggerId) {
        return;
      }
      const output = triggersAndExtra.find(trigger => trigger.id === triggerId);
      onChange(output);
    };

    const handleChangeMultiple = (triggerIds = []) => {
      if (!triggerIds.length === 0) {
        return;
      }
      const output = triggerIds.map(triggerId =>
        triggersAndExtra.find(trigger => trigger.id === triggerId),
      );
      onChange(output);
    };

    const getDivisionName = divId => allowedDivs.find(div => div.id === divId)?.name;

    return (
      <>
        <Select
          {...props}
          dropdownMatchSelectWidth={false}
          onChange={e => (props.mode === 'multiple' ? handleChangeMultiple(e) : handleChange(e))}
          className={styles.dropDownTriggers}
          loading={triggerLoading}
          value={value}
          placeholder={intl.formatMessage({ id: 'component.dropDownTriggers.placeHolder' })}
          optionFilterProp="children"
          showSearch
        >
          {triggersData?.map(item => (
            <Option key={`${item?.id}-${item.name}`} value={item?.id} name={item.name}>
              {` ${item.name} / ${getDivisionName(item.divId)}`}
            </Option>
          ))}
        </Select>
      </>
    );
  },
);

DropDownTriggers.propTypes = {
  division: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  eventType: PropTypes.string,
  value: PropTypes.string,
  extraTriggers: PropTypes.array,
};

export default DropDownTriggers;
