import React from 'react';
import styles from './styles.module.less';
import { KeyValueWidget } from 'components/KeyValueWidget';
import IntlMessages from 'util/IntlMessages';
import DisplayDuration from 'components/DisplayDuration';
import ExecutionCounter from 'components/ExecutionCounter';

export default function WorkOrderMainInfo({ data }) {
  const { startTz, endTz, timeLeft, triggers, schedule, scheduleCreatedAt } = data;
  const { taskSummary, duration } = schedule;

  return (
    <div className={styles.workOrderInfoContainer}>
      <KeyValueWidget
        label={<IntlMessages id="work.order.info.creation" />}
        value={
          <IntlMessages
            id="common.date.time.value"
            values={{
              date: scheduleCreatedAt,
            }}
          />
        }
      />
      <KeyValueWidget
        label={<IntlMessages id="work.order.info.start" />}
        value={
          <IntlMessages
            id="common.date.time.value"
            values={{
              date: startTz,
            }}
          />
        }
      />
      <KeyValueWidget
        label={<IntlMessages id="work.order.info.end" />}
        value={
          <IntlMessages
            id="common.date.time.value"
            values={{
              date: endTz,
            }}
          />
        }
      />
      <KeyValueWidget
        label={<IntlMessages id="work.order.info.duration" />}
        value={<DisplayDuration minutes={duration} />}
      />
      <KeyValueWidget
        label={<IntlMessages id="work.order.info.timeLeft" />}
        value={<DisplayDuration minutes={timeLeft} />}
      />
      <KeyValueWidget
        className={styles.executionCounter}
        label={<ExecutionCounter execution={data} triggers={triggers} />}
      />
      <KeyValueWidget
        label={<IntlMessages id="work.order.info.template" />}
        value={taskSummary?.name}
      />
    </div>
  );
}
