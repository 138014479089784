import moment from 'moment';
import React from 'react';
import IntlMessages from 'util/IntlMessages';

export const defaultAggsConfig = {
  min: {
    color: '#FE9E15',
    displayType: 'bar',
  },
  max: {
    color: '#003366',
    displayType: 'bar',
  },
  count: {
    color: '#DDAFFF',
    displayType: 'area',
  },
  sum: {
    color: '#35AFFF',
    displayType: 'area',
  },
  avg: {
    color: '#59AA2B',
    displayType: 'line',
  },
};
export const LIST_TIMER_OPTIONS = [1, 3, 5];

export const CHART_TYPES = ['bar', 'line', 'area'];

export const METRICS_FILTER = 'metrics_filter';

export const makeSubtitle = ({ isRelative, dateType }) => {
  let intMessage = 'dashboard.chart.subtitle.';

  if (isRelative) {
    intMessage += 'relative';
    intMessage += `.${dateType}`;
  } else intMessage += 'absolute';

  return intMessage;
};

export const DASHBOARDS_EVENT_TYPE = {
  CHARTS: 'charts',
  TABLES: 'tables',
};

export const COMPONENTS_OF_METRICS = {
  CHART: 'chart',
  TABLE: 'table',
};

export const COMPONENTS_OF_METRICS_OPTIONS = Object.values(COMPONENTS_OF_METRICS).map(value => ({
  value,
  label: <IntlMessages id={`dashboards.chart.form.component.${value}`} />,
}));

export const DASHBOARDS_FORMS = {
  CHART: 'chartForm',
  TABLE: 'tableForm',
};

export const INPUT_TYPES_NOT_ALLOWED = ['video', 'file', 'photo'];

// For a while we are going to use the same range when creating a new chart
export const RANGE_BY_DEFAULT = {
  isRelative: true,
  relativeAmount: 1,
  dateType: 'day',
  dateRange: [moment().subtract(6, 'month'), moment()],
};

export const DATE_FROM_DEFAULT = moment().subtract(7, 'days');

export const LAYOUT_BY_DEFAULT = { x: 1000, y: 1000, h: 2, w: 5 };

export const AUX_FIELDS_EXTRA = {
  userName: 'userName',
  eventDate: 'eventDate',
  location: 'location',
  eventType: 'eventType',
  division: 'division',
};
export const AUX_FIELDS_TABLE_FORM = [
  {
    componentId: AUX_FIELDS_EXTRA.userName,
    isActive: true,
    propertyName: <IntlMessages id="dashboards.events.table.auxFields.userName" />,
    order: null,
    index: 0,
  },
  {
    componentId: AUX_FIELDS_EXTRA.eventDate,
    isActive: true,
    propertyName: <IntlMessages id="dashboards.events.table.auxFields.eventDate" />,
    order: null,
    index: 1,
  },
  {
    componentId: AUX_FIELDS_EXTRA.eventType,
    isActive: true,
    propertyName: <IntlMessages id="dashboards.events.table.auxFields.eventType" />,
    order: null,
    index: 2,
  },
  {
    componentId: AUX_FIELDS_EXTRA.division,
    isActive: true,
    propertyName: <IntlMessages id="dashboards.events.table.auxFields.division" />,
    order: null,
    index: 3,
  },
  // NOTE: We are going to add the location field in the future
  // {
  //   componentId: AUX_FIELDS_EXTRA.location,
  //   isActive: true,
  //   propertyName: 'Ubicación',
  //   order: null,
  //   index: 2,
  // },
];

export const DASHBOARDS_COLLECTION = 'dashboards';
