/**
 * First, creates a unique array based on triggerId using Map.
 * Then, filters out records where triggerId already exists in the accumulated array.
 */
//NOTE: Remember that eventTypes is from "triggers collection"

export const removeEventTypeDuplicated = (eventTypes = []) => {
  const uniqueTriggerIdAndId = eventTypes.reduce((acc, record) => {
    const seenIds = acc.map(item => item.id);
    if (record?.triggerId && seenIds.includes(record.triggerId)) {
      return acc;
    }
    return [...acc, record];
  }, []);

  return uniqueTriggerIdAndId;
};
