import React from 'react';
import { Form, Switch } from 'antd';
import PropTypes from 'prop-types';

import IntlMessages from 'util/IntlMessages';

const SwitchInput = ({ fieldKey, extraProps, name, label, form }) => {
  const isEditing = form.getFieldValue(['components', ...name]) !== undefined;
  const defaultProps = isEditing ? {} : { initialValue: false };

  return (
    <Form.Item
      key={fieldKey}
      label={<IntlMessages id={label} />}
      name={name}
      valuePropName="checked"
      {...defaultProps}
      {...extraProps}
    >
      <Switch />
    </Form.Item>
  );
};

SwitchInput.propTypes = {
  extraProps: PropTypes.shape({}).isRequired,
  fieldKey: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.string, PropTypes.number])).isRequired,
  form: PropTypes.shape({
    getFieldValue: PropTypes.func.isRequired,
  }).isRequired,
  label: PropTypes.string.isRequired,
  name: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.string, PropTypes.number])).isRequired,
};

export default SwitchInput;
