import React, { useEffect, useState } from 'react';
import { Form, InputNumber, Switch } from 'antd';
import PropTypes from 'prop-types';
import { set } from 'lodash';
import { useIntl } from 'react-intl';

import IntlMessages from 'util/IntlMessages';

import styles from './styles.module.less';

const NumberInput = ({ fieldKey, extraProps, name, label, form, options }) => {
  const DEFAULT_DISABLE_VALUE = null;
  const DEFAULT_ENABLE_VALUE = 1;
  const intl = useIntl();
  const [enableInput, setEnableInput] = useState(!!options?.required);
  const [currentValue, setCurrentValue] = useState(DEFAULT_ENABLE_VALUE);
  const isEditing = form.getFieldValue(['components', ...name]) !== undefined;
  const defaultProps = isEditing ? {} : { initialValue: DEFAULT_DISABLE_VALUE };

  useEffect(() => {
    const savedValue = form.getFieldValue(['components', ...name]);
    if (savedValue >= 0 && savedValue !== DEFAULT_DISABLE_VALUE) {
      setEnableInput(true);
      setCurrentValue(savedValue);
    }
  }, [form, name]);

  const handleToogle = () => {
    const prev = form.getFieldValue(['components']);
    set(prev, name, enableInput ? DEFAULT_DISABLE_VALUE : currentValue);
    form.setFieldsValue({
      components: prev,
    });
    setEnableInput(!enableInput);

    if (options.dependencyName) {
      form.validateFields([
        ['components', ...name],
        ['components', ...options.dependencyName],
      ]);
    }
  };

  return (
    <Form.Item
      key={fieldKey}
      label={
        <div className={styles.label}>
          <IntlMessages id={label} />
          {!options?.required && (
            <Switch
              className="gx-ml-2"
              checked={enableInput}
              onChange={handleToogle}
              size="small"
            />
          )}
        </div>
      }
      name={name}
      {...(options.dependencyName
        ? { dependencies: ['components', ...options.dependencyName] }
        : {})}
      rules={[
        ({ getFieldValue }) => ({
          validator(rule, value) {
            if (!options.dependencyName || !enableInput) {
              return Promise.resolve();
            }
            const depValue = getFieldValue(['components', ...options.dependencyName]);
            if (options.checkMax) {
              if (depValue != null && value >= depValue) {
                return Promise.reject(
                  Error(
                    intl.formatMessage({
                      id: 'eventTypes.component.componentProps.minHigherThanMax',
                    }),
                  ),
                );
              }
              return Promise.resolve();
            }

            if (depValue != null && value <= depValue) {
              return Promise.reject(
                Error(
                  intl.formatMessage({ id: 'eventTypes.component.componentProps.maxLowerThanMin' }),
                ),
              );
            }
            return Promise.resolve();
          },
        }),
      ]}
      {...defaultProps}
      {...extraProps}
    >
      <InputNumber
        required={!!options?.required}
        disabled={!enableInput}
        hidden={!enableInput}
        min={options?.min}
        max={options?.max}
        onChange={setCurrentValue}
      />
    </Form.Item>
  );
};

NumberInput.defaultProps = {
  options: null,
};

NumberInput.propTypes = {
  extraProps: PropTypes.shape({}).isRequired,
  fieldKey: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.string, PropTypes.number])).isRequired,
  form: PropTypes.shape({
    getFieldValue: PropTypes.func.isRequired,
    setFieldsValue: PropTypes.func.isRequired,
    validateFields: PropTypes.func.isRequired,
  }).isRequired,
  label: PropTypes.string.isRequired,
  name: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.string, PropTypes.number])).isRequired,
  options: PropTypes.shape({
    min: PropTypes.number,
    max: PropTypes.number,
    required: PropTypes.bool,
    checkMax: PropTypes.bool,
    dependencyName: PropTypes.arrayOf(PropTypes.string),
  }),
};

export default NumberInput;
