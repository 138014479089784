import React, { useEffect, useRef, useState } from 'react';

import moment from 'moment';
import { useSelector } from 'react-redux';
import { Input, Skeleton, Spin, Tabs } from 'antd';
import { useHistory, useParams } from 'react-router-dom';

import { db } from 'firebase/firebase';
import { Container } from 'packages/ui';
import IntlMessages from 'util/IntlMessages';
import { collection, doc, getDoc, query, where } from 'firebase/firestore';
import {
  ALLOWED_ROLES,
  getDivsWithAccess,
  getDocData,
  useFirestoreQueryBatched,
  useIsMobile,
} from 'packages/utils';

import BoxContainer from 'components/BoxContainer';
import FilterContainer from 'components/FilterContainer';
import Title from 'components/BoxContainer/components/Title';
import styles from './styles.module.less';
import LabelValue from '../../../../components/LabelValue';
import { EVENT_DEFAULT, EVENT_OPTIONS } from './constants';
import ScheduleCard from '../../components/Notifications/ScheduleCard';

const UserNotifications = () => {
  const history = useHistory();
  const { isMobile } = useIsMobile();
  const searchRef = useRef(null);
  const { userId } = useParams();

  const selectedDivsIds = useSelector(({ divisions }) => divisions.selector.ids || []);
  const orgId = useSelector(({ organizations }) => organizations.organization.id);
  const [dataFiltered, setDataFiltered] = useState([]);
  const [eventSelected, setEventSelected] = useState(EVENT_DEFAULT);
  const [userSelected, setUserSelected] = useState({});
  const hasUser = Object.keys(userSelected).length > 0;

  const onNotificationChange = optionIdx => {
    setEventSelected(optionIdx);
  };
  const optionsTranslated = EVENT_OPTIONS.map(event => ({
    ...event,
    label: <IntlMessages id={`user.tab.option.${event.name}`} />,
  }));

  const userOrgAccess = userSelected?.access;
  const userOrgDivisions = userSelected?.divisions || [];
  const allowedDivs = getDivsWithAccess(
    ALLOWED_ROLES.ORGANIZATIONS.DIVISIONS.SCHEDULES.LIST,
    userOrgAccess,
    userOrgDivisions,
  );
  const allowedDivsIds = allowedDivs.map(d => d.id);
  const allowedSelectedDivs = selectedDivsIds.filter(divId => allowedDivsIds.includes(divId));

  // NOTE: get only schedule actives
  const currentDate = moment().toDate();
  const queriesList = allowedSelectedDivs.map(divId =>
    query(
      collection(db, 'organizations', orgId, 'divisions', divId, 'schedules'),
      where('schedule.endAt', '>', currentDate),
    ),
  );
  const {
    data: scheduleList,
    loading: scheduleListLoading,
    loaded,
  } = useFirestoreQueryBatched(queriesList, [selectedDivsIds]);

  const handleSearch = value => {
    if (!value) setDataFiltered(scheduleList);
    else {
      const output = scheduleList.filter(item => {
        const textToFind = item.name.toLowerCase();
        const pattern = new RegExp(value.toLowerCase(), 'g');

        if (textToFind.match(pattern)?.length) return true;
        return false;
      });

      setDataFiltered(output);
    }
  };

  const loading = scheduleListLoading && !hasUser;

  useEffect(() => {
    const searchValue = searchRef?.current?.input?.value;
    if (!scheduleListLoading && scheduleList?.length !== dataFiltered?.length && !searchValue) {
      setDataFiltered(scheduleList);
    }
  }, [scheduleList, dataFiltered, loaded, scheduleListLoading]);

  useEffect(() => {
    const fetchUser = async () => {
      const user = await getDocData(await getDoc(doc(db, 'organizations', orgId, 'users', userId)));
      if (!user) history.push('/');
      setUserSelected(user);
    };
    fetchUser();
  }, [history, orgId, userId]);

  if (loading) return <Spin />;

  return (
    <BoxContainer>
      <BoxContainer content shadow fixed>
        <FilterContainer
          goBack={() => history.goBack()}
          title={<Title.Header value={<IntlMessages id="user.notifications.title" />} />}
        />
      </BoxContainer>
      <BoxContainer content>
        <Skeleton paragraph={{ rows: 1 }} size="small" loading={!userSelected}>
          <p className={styles.subTitle}>
            <IntlMessages
              id="user.notification.config.to"
              values={{ name: `${userSelected?.firstName} ${userSelected?.lastName}` }}
            />
          </p>
        </Skeleton>
        <Tabs
          onChange={onNotificationChange}
          className="gx-pb-2"
          defaultActiveKey={eventSelected}
          defaultValue={eventSelected}
          items={optionsTranslated}
        />
        <LabelValue
          vertical
          name={<IntlMessages id="user.notification.search.schedule" />}
          value={<Input.Search allowClear ref={searchRef} onSearch={handleSearch} />}
        />
        {!isMobile && loaded && hasUser && (
          <Container loading={loading} className={styles.scheduleListDesk}>
            {dataFiltered.map(schedule => (
              <div key={schedule.id}>
                <ScheduleCard data={schedule} currentUser={userSelected} />
              </div>
            ))}
          </Container>
        )}

        {isMobile && (
          <div className={styles.info}>
            <p>
              <IntlMessages id="user.notification.info" />
            </p>
          </div>
        )}
        {isMobile && loaded && hasUser && (
          <Container loading={loading} className={styles.scheduleList}>
            {dataFiltered.map(schedule => (
              <div key={schedule.id}>
                <ScheduleCard data={schedule} currentUser={userSelected} />
              </div>
            ))}
          </Container>
        )}
      </BoxContainer>
    </BoxContainer>
  );
};
export default UserNotifications;
